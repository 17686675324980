import { useState, useEffect } from 'react';
type ImageData = {
  mediaURL: string;
  tags: string;
  colorSpace?: string;  // Optional, if not always available
  data?: Uint8ClampedArray;  // Optional, for actual image data if needed
  height?: number;  // Optional, specify if needed
  width?: number;  // Optional, specify if needed
};

const useFetchImages = (enKey: string, page: number = 1) => {
  const [images, setImages] = useState<ImageData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(page);
  const [hasMore, setHasMore] = useState(true);

  const fetchImages = async (query: string, pageNumber: number) => {
    setIsLoading(true);
    try {
      const url = new URL('https://wb1wp5h089.execute-api.ap-northeast-1.amazonaws.com/dev/pixabay-api');
      const params = new URLSearchParams({
        q: query,
        orientation: 'horizontal',
        video: 'false',
        page: pageNumber.toString()
      });
      url.search = params.toString();

      const response = await fetch(url.toString());
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      if (data && data.mediaResults) {
        const newImages: ImageData[] = data.mediaResults.map((item: any) => ({
          mediaURL: item.mediaURL,
          tags: item.tags,
          colorSpace: 'RGB',
          height: 300,  // 統一感のため300pxに設定
          width: 300   // 統一感のため300pxに設定
        }));

        setImages(prevImages => [...prevImages, ...newImages]);
        setHasMore(newImages.length > 0);
      } else {
        console.error('Invalid format of fetched data', data);
        setHasMore(false);
      }
    } catch (error) {
      console.error('Failed to fetch images', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (enKey) fetchImages(enKey, currentPage);
  }, [enKey, currentPage]);

  const loadMoreImages = () => {
    if (hasMore && !isLoading) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  return { images, isLoading, loadMoreImages, hasMore };
};

export default useFetchImages;
