import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { Box, Typography, List, ListItem, ListItemText, Button, Divider } from "@mui/material";
import { Link } from "react-router-dom";
import { db } from "../config/firebase";
import { useTranslation } from "react-i18next"; // useTranslationを追加
import moment from "moment"; // 時間フォーマット用
import { FC, useEffect, useState } from "react";

// NewsItem 型を定義
type NewsItem = {
  id: string;
  title: string;
  description: string;
  link: string;
  pubDate: Date;
};

export const FeedArticlePage: FC = () => {
  const { t } = useTranslation(); // useTranslationを呼び出し
  const [allNews, setAllNews] = useState<NewsItem[]>([]); // 型をNewsItem[]に変更

  useEffect(() => {
    const fetchAllNews = async () => {
      const newsCollectionRef = collection(db, "news");
      const q = query(newsCollectionRef, orderBy("pubDate", "desc"));
      const snapshot = await getDocs(q);

      const newsList: NewsItem[] = snapshot.docs.map((doc) => ({
        id: doc.id,
        title: doc.data().title,
        description: doc.data().description,
        link: doc.data().link,
        pubDate: doc.data().pubDate.toDate(), // Date型に変換
      }));
      setAllNews(newsList); // 型が一致するように設定
    };

    fetchAllNews();
  }, []);

  return (
    <Box sx={{ width: "80%", m: "auto", p: 3 }}>
      <Typography variant="h4">{t("記事一覧")}</Typography>
      <List>
        {allNews.map((newsItem) => (
          <Box key={newsItem.id}>
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="body1">
                    <strong>{newsItem.title}</strong> - {moment(newsItem.pubDate).format("YYYY-MM-DD")}
                  </Typography>
                }
                secondary={
                  <Typography variant="body2" color="textSecondary">
                    {newsItem.description}
                  </Typography>
                }
              />
              <Button component={Link} to={newsItem.link} sx={{ ml: 2 }}>
                {t("記事を読む")}
              </Button>
            </ListItem>
            <Divider />
          </Box>
        ))}
      </List>
    </Box>
  );
};
