import {
  Home as HomeIcon,
  Settings as SettingsIcon,
  Article as ArticleIcon,
  TrendingUp as TrendingIcon,
  Build as BuildIcon,
  TextFields as TextIcon,
  Image as ImageIcon,
  Security as SecurityIcon,
  Policy as PolicyIcon,
  Gavel as GavelIcon,
  Person as PersonIcon,
} from "@mui/icons-material";
import { NavigationItem, NavigationSingleItem, NavigationGroupItem } from "../NavigationList";

const createNavigationSingleItem = (item: NavigationSingleItem) => item;
const createNavigationGroupItem = (item: NavigationGroupItem) => item;

export const NAVIGATION_ITEMS: NavigationItem[] = [
  // createNavigationSingleItem({
  //   icon: HomeIcon,
  //   title: 'HOME',
  //   href: "/",
  // }),
  createNavigationGroupItem({
    icon: BuildIcon, // RAG Suiteに合わせて設定
    title: 'RAG Suite',
    subs: [
      // createNavigationSingleItem({
      //   title: 'RAG Suiteとは',
      //   href: "/",
      // }),
      createNavigationSingleItem({
        icon: TrendingIcon, // BuzzAPIのアイコン
        title: 'BuzzAPI',
        href: "/trends",
      }),
      // createNavigationSingleItem({
      //   icon: ImageIcon, // ImageAPIのアイコン
      //   title: 'ImageAPI',
      //   href: "/images",
      // }),
      createNavigationSingleItem({
        icon: TextIcon, // RAG Makerのアイコン
        title: 'RAG Maker',
        href: "/ragmaker",
      }),
      createNavigationSingleItem({
        icon: TextIcon, // KW Searchのアイコン
        title: 'KW Search',
        href: "/article/new",
      }),
      createNavigationSingleItem({
        icon: TextIcon, // KW Searchのアイコン
        title: 'Chat',
        href: "/chat",
      }),
      createNavigationSingleItem({
        icon: TextIcon, // KW Searchのアイコン
        title: 'ChatBot',
        href: "/chatbot",
      }),
      createNavigationSingleItem({
        title: 'Feed一覧', // Feed一覧ページに「Feed登録」リンクを追加
        href: "/feeds",
      }),
      
      createNavigationSingleItem({
        title: 'プロンプト一覧', // プロンプト一覧ページに「新規プロンプト登録」リンクを追加
        href: "/prompts",
      }),
      createNavigationSingleItem({
        title: 'Rag一覧', // プロンプト一覧ページに「新規プロンプト登録」リンクを追加
        href: "/rags",
      }),
    ],
  }),
  createNavigationGroupItem({
    icon: SettingsIcon, // AI Suiteに合わせて設定
    title: 'AI Suite',
    subs: [
      createNavigationSingleItem({
        icon: TextIcon, // AI Writerのアイコン
        title: 'AI Writer',
        href: "/article/new",
      }),
      createNavigationSingleItem({
        icon: HomeIcon, // AI MANGA BOTのアイコン
        title: 'AI MANGA BOT',
        href: "https://ai-line-bot-f35b5.firebaseapp.com/",
      }),
    ],
  }),
  createNavigationSingleItem({
    icon: SecurityIcon, // Subscriptionのアイコン
    title: 'Subscription',
    href: "/settings/plan",
  }),
  createNavigationGroupItem({
    icon: SettingsIcon, // Settingに合わせて設定
    title: 'Setting',
    subs: [
      createNavigationSingleItem({
        icon: SecurityIcon, // パスワード設定のアイコン
        title: 'パスワード設定',
        href: "/auth/forgot-password",
      }),
      createNavigationSingleItem({
        icon: PolicyIcon, // プライバシーポリシーのアイコン
        title: 'プライバシーポリシー',
        href: "/rule/privacy-policy",
      }),
      createNavigationSingleItem({
        icon: GavelIcon, // 利用規約のアイコン
        title: '利用規約',
        href: "/rule/term-of-service",
      }),
    ],
  }),
  createNavigationSingleItem({
    icon: ArticleIcon, // Companyのアイコン
    title: 'Company',
    href: "http://zero-one.llc/",
  }),
];
