// import { FC } from "react";

// import { Container, List, ListItem, Typography } from '@mui/material';
// export const PrivacyPolicyPage: FC = () => {
//   return (
//     <Container component="main" maxWidth="md" sx={{ mt: 4, mb: 4 }}>
//       <Typography variant="h4" gutterBottom>
//         プライバシーポリ
//       </Typography>
//       <Typography variant="h5" gutterBottom>
//         お客様から取得する情報
//       </Typography>

// <List>
// <ListItem>氏名(ニックネームやペンネームも含む) </ListItem>

// <ListItem>年齢または生年月日</ListItem>

// <ListItem>性別</ListItem>

// <ListItem>職業、職歴、学歴</ListItem>

// <ListItem>メールアドレス</ListItem>

// <ListItem>電話番号</ListItem>

// <ListItem>住所</ListItem>

// <ListItem>写真や動画</ListItem>

// <ListItem>クレジットカード、銀行口座、電子マネー等のお客様の決済手段に関する情報</ListItem>

// <ListItem>外部サービスでお客様が利用するID、その他外部サービスのプライバシー設定によりお客様が連携先に開示を認めた情報</ListItem>

// <ListItem>Cookie(クッキー)を用いて生成された識別情報</ListItem>

// <ListItem>OSが生成するID、端末の種類、端末識別子等のお客様が利用するOSや端末に関する情報</ListItem>

// <ListItem>当社ウェブサイトの滞在時間、入力履歴、購買履歴等の当社ウェブサイトにおけるお客様の行動履歴</ListItem>

// <ListItem>当社アプリの起動時間、入力履歴、購買履歴等の当社アプリの利用履歴</ListItem>
// </List>
// <Typography variant="h5">お客様の情報を利用する目的</Typography>
// 当社は、お客様から取得した情報を、以下の目的のために利用します。
// <List>
// <ListItem>当社サービスに関する登録の受付、お客様の本人確認、認証のため </ListItem>

// <ListItem>お客様の当社サービスの利用履歴を管理するため</ListItem>

// <ListItem>利用料金の決済のため</ListItem>

// <ListItem>当社サービスにおけるお客様の行動履歴を分析し、当社サービスの維持改善に役立てるため </ListItem>

// <ListItem>当社のサービスに関するご案内をするため</ListItem>

// <ListItem>お客様からのお問い合わせに対応するため</ListItem>

// <ListItem>当社の規約や法令に違反する行為に対応するため</ListItem>

// <ListItem>当社サービスの変更、提供中止、終了、契約解除をご連絡するため</ListItem>

// <ListItem>当社規約の変更等を通知するため</ListItem>

// <ListItem>以上の他、当社サービスの提供、維持、保護及び改善のため</ListItem>
// </List>
// <Typography variant="h5">安全管理のために講じた措置</Typography>
// 当社が、お客様から取得した情報に関して安全管理のために講じた措置につきましては、末尾記載のお問い合わせ先にご連絡をいただきましたら、法令の定めに従い個別にご回答させていただきます。

// <Typography variant="h5">第三者提供</Typography>
// 当社は、お客様から取得する情報のうち、個人データ（個人情報保護法第２条第６項）に該当するものついては、あらかじめお客様の同意を得ずに、第三者（日本国外にある者を含みます。）に提供しません。
// 但し、次の場合は除きます。
// <List>
// <ListItem>個人データの取扱いを外部に委託する場合 </ListItem>

// <ListItem>当社や当社サービスが買収された場合</ListItem>

// <ListItem>事業パートナーと共同利用する場合（具体的な共同利用がある場合は、その内容を別途公表します。）</ListItem>

// <ListItem>その他、法律によって合法的に第三者提供が許されている場合</ListItem>
// </List>
// <Typography variant="h5">アクセス解析ツール</Typography>
// 当社は、お客様のアクセス解析のために、「Googleアナリティクス」を利用しています。Googleアナリティクスは、トラフィックデータの収集のためにCookieを使用しています。トラフィックデータは匿名で収集されており、個人を特定するものではありません。Cookieを無効にすれば、これらの情報の収集を拒否することができます。詳しくはお使いのブラウザの設定をご確認ください。Googleアナリティクスについて、詳しくは以下からご確認ください。
// https://marketingplatform.google.com/about/analytics/terms/jp/

// <Typography variant="h5">プライバシーポリシーの変更</Typography>
// 当社は、必要に応じて、このプライバシーポリシーの内容を変更します。この場合、変更後のプライバシーポリシーの施行時期と内容を適切な方法により周知または通知します。

// <Typography variant="h5">お問い合わせ</Typography>
// お客様の情報の開示、情報の訂正、利用停止、削除をご希望の場合は、以下のメールアドレスにご連絡ください。

// e-mail
// info@zero-one.llc

// この場合、必ず、運転免許証のご提示等当社が指定する方法により、ご本人からのご請求であることの確認をさせていただきます。なお、情報の開示請求については、開示の有無に関わらず、ご申請時に一件あたり1,000円の事務手数料を申し受けます。

// 事業者の名称
// ゼロイチ合同会社

// 代表者氏名
// 竹中宏道

// 事業者の住所
// 東京都中央区銀座１丁目１２番４号６F

// 2023年02月21日 制定

//       </Container>
//   );
// };

import { FC } from "react";
import React from 'react';
import { Container, List, ListItem, Typography, Link } from '@mui/material';

export const PrivacyPolicyPage: FC = () => {
  return (
    <Container component="main" maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>
        プライバシーポリシー
      </Typography>

      <Typography variant="h5" gutterBottom>
        お客様から取得する情報
      </Typography>
      <List>
        <ListItem>氏名（ニックネームやペンネームも含む）</ListItem>
        <ListItem>年齢または生年月日</ListItem>
        <ListItem>性別</ListItem>
        <ListItem>職業、職歴、学歴</ListItem>
        <ListItem>メールアドレス</ListItem>
        <ListItem>電話番号</ListItem>
        <ListItem>住所</ListItem>
        <ListItem>写真や動画</ListItem>
        <ListItem>クレジットカード、銀行口座、電子マネー等の決済手段に関する情報</ListItem>
        <ListItem>外部サービスでのID、その他外部サービスのプライバシー設定により公開を許可された情報</ListItem>
        <ListItem>Cookieを用いた識別情報</ListItem>
        <ListItem>OSが生成するID、端末の種類、端末識別子等のデバイス情報</ListItem>
        <ListItem>ウェブサイトの滞在時間、入力履歴、購買履歴等の行動履歴</ListItem>
        <ListItem>アプリの起動時間、入力履歴、購買履歴等の利用履歴</ListItem>
      </List>

      <Typography variant="h5" gutterBottom>
        お客様の情報を利用する目的
      </Typography>
      <List>
        <ListItem>サービスに関する登録の受付、本人確認、認証</ListItem>
        <ListItem>サービスの利用履歴の管理</ListItem>
        <ListItem>利用料金の決済</ListItem>
        <ListItem>サービスの維持改善のための行動履歴の分析</ListItem>
        <ListItem>サービス関連の情報の提供</ListItem>
        <ListItem>ユーザーからの問い合わせへの対応</ListItem>
        <ListItem>規約や法令に違反する行為への対応</ListItem>
        <ListItem>サービスの変更、終了の通知</ListItem>
        <ListItem>規約の変更の通知</ListItem>
      </List>

      <Typography variant="h5" gutterBottom>
        第三者提供について
      </Typography>
      <List>
        <ListItem>個人データの取扱いを外部委託する場合</ListItem>
        <ListItem>事業の買収時</ListItem>
        <ListItem>共同利用する事業パートナー（具体的な内容は別途公表）</ListItem>
        <ListItem>法律に基づく正当な理由がある場合</ListItem>
      </List>

      <Typography variant="h5" gutterBottom>
        アクセス解析ツール
      </Typography>
      <Typography>
        当社は、「Googleアナリティクス」を使用しており、トラフィックデータの収集にはCookieを使用します。これは完全に匿名で行われ、個人を特定するものではありません。詳細は
        <Link href="https://marketingplatform.google.com/about/analytics/terms/jp/" target="_blank">こちら</Link>。
      </Typography>
      <Typography variant="h5" gutterBottom>
        Google広告のデータ処理
      </Typography>
      <Typography>
        当社アプリケーションでは、Google広告のAPIを利用しております。これには、Google広告アカウントのデータの参照、編集、作成、および削除が含まれます。これにより、お客様の広告のパフォーマンスを向上させ、より効果的な広告戦略を実施するための洞察を提供します。当社はお客様の広告データを厳密に管理し、これらの情報はお客様の事前の同意なく第三者に公開されることはありません。
      </Typography>
      <Typography variant="h5" gutterBottom>
        プライバシーポリシーの変更
      </Typography>
      <Typography>
        プライバシーポリシーは必要に応じて変更されることがあります。変更内容はウェブサイト上で通知されます。
      </Typography>

      <Typography variant="h5" gutterBottom>
        お問い合わせ
      </Typography>
      <Typography>
        情報の開示、訂正、利用停止、削除を希望する場合は、<Link href="mailto:info@zero-one.llc">info@zero-one.llc</Link>までご連絡ください。
      </Typography>
    </Container>
  );
};

// export default PrivacyPolicyPage;

