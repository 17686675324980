import { FC, useState } from "react";
import { Box, Button, Typography, Stack, TextField, CircularProgress, Alert } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../config/firebase";
import { useAppContext } from "../context/AppContext";
import { useTranslation } from "react-i18next";

type Form = {
  name: string;
  content: string;
  tags: string;
  category: string;
};

export const RagNewPage: FC = () => {
  const { control, handleSubmit, formState: { errors } } = useForm<Form>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { userId } = useAppContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const submit = async (data: Form) => {
    if (isSubmitting) return; // 二重送信防止

    setIsSubmitting(true);
    setErrorMessage(null);

    try {
      // Firebaseに新しいRAGを登録
      const ragCollectionRef = collection(db, "rags");
      await addDoc(ragCollectionRef, {
        name: data.name,
        content: data.content,
        tags: data.tags.split(",").map(tag => tag.trim()), // カンマ区切りで配列に変換
        category: data.category,
        userId: userId,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        status: 1, // 1 = open
      });
      navigate("/rags"); // 登録後に一覧ページへ移動
    } catch (error) {
      console.error("RAGの登録に失敗しました", error);
      setErrorMessage("RAGの登録中にエラーが発生しました。再試行してください。");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      component="form"
      noValidate
      onSubmit={handleSubmit(submit)}
      sx={{ p: 5, mt: 4, mx: "auto", maxWidth: "500px", border: '1px dashed grey', borderRadius: 2, backgroundColor: '#f7f7f7' }}
    >
      <Stack spacing={3}>
        <Typography variant="h5" textAlign="center">
          {t('新しいRAGを登録')}
        </Typography>

        <Controller
          name="name"
          control={control}
          defaultValue=""
          rules={{ required: 'RAG名は必須です' }}
          render={({ field }) => (
            <TextField
              {...field}
              label="RAG名"
              fullWidth
              error={!!errors.name}
              helperText={errors.name?.message}
            />
          )}
        />

        <Controller
          name="content"
          control={control}
          defaultValue=""
          rules={{
            required: 'コンテンツは必須です',
            maxLength: { value: 2000, message: 'コンテンツは2000文字以内です' }
          }}
          render={({ field }) => (
            <TextField
              {...field}
              label="コンテンツ"
              fullWidth
              multiline
              rows={8}
              error={!!errors.content}
              helperText={errors.content?.message}
            />
          )}
        />

        <Controller
          name="tags"
          control={control}
          defaultValue=""
          rules={{ required: 'タグは必須です' }}
          render={({ field }) => (
            <TextField
              {...field}
              label="タグ (カンマ区切り)"
              fullWidth
              error={!!errors.tags}
              helperText={errors.tags?.message}
            />
          )}
        />

        <Controller
          name="category"
          control={control}
          defaultValue=""
          rules={{ required: 'カテゴリーは必須です' }}
          render={({ field }) => (
            <TextField
              {...field}
              label="カテゴリー"
              fullWidth
              error={!!errors.category}
              helperText={errors.category?.message}
            />
          )}
        />

        {errorMessage && (
          <Alert severity="error">{errorMessage}</Alert>
        )}

        <Button type="submit" variant="contained" fullWidth disabled={isSubmitting}>
          {isSubmitting ? <CircularProgress size={24} /> : t('登録する')}
        </Button>
      </Stack>
    </Box>
  );
};
