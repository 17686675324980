import { FC, useState, useEffect } from "react";
import { Box, Button, Typography, Stack, TextField, CircularProgress, Alert } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useParams } from "react-router-dom";
import { doc, getDoc, updateDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../config/firebase";
import { useAppContext } from "../context/AppContext";
import { useTranslation } from "react-i18next";

type Form = {
  name: string;
  promptText: string;
};

export const PromptEditPage: FC = () => {
  const { control, handleSubmit, setValue, formState: { errors } } = useForm<Form>();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { userId } = useAppContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    const fetchPrompt = async () => {
      if (!id) return;
      try {
        const promptRef = doc(db, "prompts", id);
        const promptSnap = await getDoc(promptRef);

        if (promptSnap.exists()) {
          const promptData = promptSnap.data();
          setValue("name", promptData.name);
          setValue("promptText", promptData.promptText);
        } else {
          setErrorMessage("指定されたプロンプトが見つかりませんでした。");
        }
      } catch (error) {
        console.error("プロンプトの取得に失敗しました", error);
        setErrorMessage("プロンプトの読み込み中にエラーが発生しました。再試行してください。");
      } finally {
        setLoading(false);
      }
    };

    fetchPrompt();
  }, [id, setValue]);

  const submit = async (data: Form) => {
    if (isSubmitting || !id) return; // 二重送信防止とIDがない場合

    setIsSubmitting(true);
    setErrorMessage(null);

    try {
      // Firebaseのプロンプトを更新
      const promptRef = doc(db, "prompts", id);
      await updateDoc(promptRef, {
        name: data.name,
        promptText: data.promptText,
        updatedAt: serverTimestamp(),
      });
      navigate("/prompts"); // 更新後に一覧ページへ移動
    } catch (error) {
      console.error("プロンプトの更新に失敗しました", error);
      setErrorMessage("プロンプトの更新中にエラーが発生しました。再試行してください。");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      component="form"
      noValidate
      onSubmit={handleSubmit(submit)}
      sx={{ p: 5, mt: 4, mx: "auto", maxWidth: "90%", border: '1px dashed grey', borderRadius: 2, backgroundColor: '#f7f7f7' }}
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <Stack spacing={3}>
          <Typography variant="h5" textAlign="center">
            {t('プロンプトを編集')}
          </Typography>

          <Controller
            name="name"
            control={control}
            defaultValue=""
            rules={{ required: 'プロンプト名は必須です' }}
            render={({ field }) => (
              <TextField
                {...field}
                label="プロンプト名"
                fullWidth
                error={!!errors.name}
                helperText={errors.name?.message}
              />
            )}
          />

          <Controller
            name="promptText"
            control={control}
            defaultValue=""
            rules={{
              required: 'プロンプトテキストは必須です',
              maxLength: { value: 1000, message: 'プロンプトテキストは1000文字以内です' }
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="プロンプトテキスト"
                fullWidth
                multiline
                rows={20}
                error={!!errors.promptText}
                helperText={errors.promptText?.message}
              />
            )}
          />

          {errorMessage && (
            <Alert severity="error">{errorMessage}</Alert>
          )}

          <Button type="submit" variant="contained" fullWidth disabled={isSubmitting}>
            {isSubmitting ? <CircularProgress size={24} /> : t('更新する')}
          </Button>
        </Stack>
      )}
    </Box>
  );
};