import { FC, useState } from "react";
import { Box, Button, Typography, Stack, TextField, CircularProgress, Alert, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
import { collection, addDoc, serverTimestamp, query, where, getDocs } from "firebase/firestore";
import { db } from "../config/firebase";
import { useAppContext } from "../context/AppContext";
import { useTranslation } from "react-i18next";

type Form = {
  url: string;
  title: string;
  rssUrl: string;
  language: string;
  tags: string;
  category: string;
};

export const FeedNewPage: FC = () => {
  const { control, handleSubmit, formState: { errors } } = useForm<Form>();
  const navigate = useNavigate();
  const { t } = useTranslation();  
  const { userId } = useAppContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const checkForDuplicate = async (field: string, value: string) => {
    const feedCollectionRef = collection(db, "feeds");
    const q = query(feedCollectionRef, where(field, "==", value));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty; // true if duplicate exists
  };

  const submit = async (data: Form) => {
    if (isSubmitting) return;

    setIsSubmitting(true);
    setErrorMessage(null);

    try {
      const isUrlDuplicate = await checkForDuplicate("url", data.url);
      const isRssUrlDuplicate = await checkForDuplicate("rss_url", data.rssUrl);

      if (isUrlDuplicate || isRssUrlDuplicate) {
        setErrorMessage("URLまたはRSS URLがすでに存在します");
        return;
      }

      const newfeedRef = collection(db, "feeds");
      await addDoc(newfeedRef, {
        url: data.url,
        rss_url: data.rssUrl,
        title: data.title,
        language: data.language,
        tags: data.tags.split(',').map(tag => tag.trim()),
        category: data.category,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        status: 'active',
        userId: userId,
      });
      navigate("/feeds");
    } catch (error) {
      console.error("フィードの登録に失敗しました", error);
      setErrorMessage("フィードの登録中にエラーが発生しました。再試行してください。");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      component="form"
      noValidate
      onSubmit={handleSubmit(submit)}
      sx={{ p: 5, mt: 4, mx: "auto", maxWidth: "500px", border: '1px dashed grey', borderRadius: 2, backgroundColor: '#f7f7f7' }}
    >
      <Stack spacing={3}>
        <Typography variant="h5" textAlign="center">
          {t('新しいフィードを登録')}
        </Typography>

        <Controller
          name="url"
          control={control}
          defaultValue=""
          rules={{
            required: 'URLは必須です',
            pattern: {
              value: /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/,
              message: '有効なURLを入力してください'
            }
          }}
          render={({ field }) => (
            <TextField
              {...field}
              label="URL"
              fullWidth
              placeholder="http://example.com"
              error={!!errors.url}
              helperText={errors.url?.message}
            />
          )}
        />

        <Controller
          name="title"
          control={control}
          defaultValue=""
          rules={{ required: 'タイトルは必須です' }}
          render={({ field }) => (
            <TextField
              {...field}
              label="タイトル"
              fullWidth
              error={!!errors.title}
              helperText={errors.title?.message}
            />
          )}
        />

        <Controller
          name="rssUrl"
          control={control}
          defaultValue=""
          rules={{
            required: 'RSS URLは必須です',
            pattern: {
              value: /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/,
              message: '有効なRSS URLを入力してください'
            }
          }}
          render={({ field }) => (
            <TextField
              {...field}
              label="RSS URL"
              fullWidth
              error={!!errors.rssUrl}
              helperText={errors.rssUrl?.message}
            />
          )}
        />

        <FormControl fullWidth>
          <InputLabel>言語</InputLabel>
          <Controller
            name="language"
            control={control}
            defaultValue="ja"
            render={({ field }) => (
              <Select {...field} label="言語">
                <MenuItem value="ja">日本語</MenuItem>
                <MenuItem value="en">英語</MenuItem>
                <MenuItem value="es">スペイン語</MenuItem>
                <MenuItem value="fr">フランス語</MenuItem>
              </Select>
            )}
          />
        </FormControl>

        <FormControl fullWidth>
          <InputLabel>カテゴリ</InputLabel>
          <Controller
            name="category"
            control={control}
            defaultValue="ニュース"
            render={({ field }) => (
              <Select {...field} label="カテゴリ">
                <MenuItem value="ニュース">ニュース</MenuItem>
                <MenuItem value="テクノロジー">テクノロジー</MenuItem>
                <MenuItem value="エンタメ">エンタメ</MenuItem>
                <MenuItem value="ビジネス">ビジネス</MenuItem>
                <MenuItem value="スポーツ">スポーツ</MenuItem>
                <MenuItem value="ライフスタイル">ライフスタイル</MenuItem>
                <MenuItem value="ヘルス">ヘルス</MenuItem>
              </Select>
            )}
          />
        </FormControl>

        <Controller
          name="tags"
          control={control}
          defaultValue=""
          rules={{
            required: 'タグは必須です',
            maxLength: { value: 100, message: 'タグは100文字以内にしてください' }
          }}
          render={({ field }) => (
            <TextField
              {...field}
              label="タグ (カンマ区切り)"
              fullWidth
              error={!!errors.tags}
              helperText={errors.tags?.message || '例: テクノロジー, AI, ニュース'}
            />
          )}
        />

        {errorMessage && (
          <Alert severity="error">{errorMessage}</Alert>
        )}

        <Button type="submit" variant="contained" fullWidth disabled={isSubmitting}>
          {isSubmitting ? <CircularProgress size={24} /> : t('登録する')}
        </Button>
      </Stack>
    </Box>
  );
};
