import React, { FC, useState, useEffect, useRef } from "react";
import { Box, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Button, TextField, Grid } from "@mui/material";
import { db } from "../config/firebase";
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import axios from "axios";

interface NewsItem {
  id: string;
  title: string;
  link: string;
  pubDate: Date;
  feedId: string;
}

interface FeedItem {
  id: string;
  title: string;
}

export const TrendSearchPage: FC = () => {
  const [allNews, setAllNews] = useState<NewsItem[]>([]);
  const [allFeeds, setAllFeeds] = useState<FeedItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [inputKeyword, setInputKeyword] = useState<string>("");
  const [filteredNews, setFilteredNews] = useState<NewsItem[]>([]);
  const [isLoadingRAG, setIsLoadingRAG] = useState<boolean>(false); // RAG生成中のローディングフラグ
  const [s3Links, setS3Links] = useState<string[]>([]); // 生成されたS3リンク
  const logRef = useRef<HTMLDivElement | null>(null);

  // Firestoreからニュースとフィードを取得
  const fetchAllNews = async () => {
    const newsCollectionRef = collection(db, "news");
    const q = query(newsCollectionRef, orderBy("pubDate", "desc"));
    const snapshot = await getDocs(q);
    const newsList: NewsItem[] = snapshot.docs.map((doc) => ({
      id: doc.id,
      title: doc.data().title,
      link: doc.data().link,
      pubDate: doc.data().pubDate.toDate(),
      feedId: doc.data().feedId,
    }));
    setAllNews(newsList);
    setFilteredNews(newsList);
  };

  const fetchAllFeeds = async () => {
    const feedsCollectionRef = collection(db, "feeds");
    const snapshot = await getDocs(feedsCollectionRef);
    const feedsList: FeedItem[] = snapshot.docs.map((doc) => ({
      id: doc.id,
      title: doc.data().title,
    }));
    setAllFeeds(feedsList);
  };

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([fetchAllNews(), fetchAllFeeds()]);
      setLoading(false);
    };
    fetchData();
  }, []);

  // キーワード検索
  const handleKeywordSubmit = () => {
    const lowercasedKeyword = inputKeyword.toLowerCase();
    const filtered = allNews.filter(newsItem =>
      newsItem.title.toLowerCase().includes(lowercasedKeyword)
    );
    setFilteredNews(filtered);
  };

  // RAG生成のためのAPI呼び出し
  const researchCompetitorSite = async (url: string) => {
    setIsLoadingRAG(true);
    setS3Links([]); // リンクリセット
    try {
      const response = await axios.get(
        `https://wb1wp5h089.execute-api.ap-northeast-1.amazonaws.com/dev/crawlee?urls=${encodeURIComponent(url)}`
      );
      const s3Urls = response.data;
      setS3Links(s3Urls); // 取得したS3リンクをセット
      console.log("S3 Links received:", s3Urls);
    } catch (error) {
      console.error("Error fetching data from competitor site:", error);
      alert("RAG生成に失敗しました。再度お試しください。");
    } finally {
      setIsLoadingRAG(false);
      if (logRef.current) {
        logRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  // RAG生成ボタンの処理
  const handleRAGGenerate = (newsItem: NewsItem) => {
    researchCompetitorSite(newsItem.link);
  };

  return (
    <Box sx={{ width: "90%", margin: "0 auto" }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} lg={8}>
          {/* キーワード検索フォーム */}
          <TextField 
            variant="outlined" 
            margin="normal"
            fullWidth 
            label="キーワード"
            value={inputKeyword}
            onChange={(e) => setInputKeyword(e.target.value)}
            disabled={isLoadingRAG} // RAG生成中は入力を無効化
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <Button 
            variant="contained" 
            color="primary" 
            fullWidth 
            onClick={handleKeywordSubmit}
            disabled={isLoadingRAG} // RAG生成中はボタンを無効化
          >
            <ArrowDownwardIcon />
            キーワード検索
            <ArrowDownwardIcon />
          </Button>
        </Grid>
      </Grid>

      {/* RAG生成中のインジケーター */}
      {isLoadingRAG && (
        <Box sx={{ textAlign: 'center', mt: 2 }}>
          <CircularProgress size={24} />
          <Typography variant="body2" sx={{ ml: 1 }}>RAG生成中...しばらくお待ちください。</Typography>
        </Box>
      )}

      {loading ? (
        <Box sx={{ my: 20, textAlign: "center" }}>
          ニュースの読み込み中
          <CircularProgress />
        </Box>
      ) : (
        allFeeds.map(feed => {
          const newsItems = filteredNews
            .filter(newsItem => newsItem.feedId === feed.id)
            .slice(0, 5); // 最新5件のみ表示

          if (newsItems.length === 0) return null;

          return (
            <Box key={feed.id} sx={{ mb: 3 }}>
              <Typography variant="h6">{feed.title}</Typography>
              <TableContainer>
                <Table sx={{ border: '1px solid black' }}>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ border: '1px solid black', backgroundColor: '#f5f5f5', padding: '8px' }}>タイトル</TableCell>
                      <TableCell sx={{ border: '1px solid black', backgroundColor: '#f5f5f5', padding: '8px', width: '100px' }}>日付</TableCell>
                      <TableCell sx={{ border: '1px solid black', backgroundColor: '#f5f5f5', padding: '8px', width: '150px' }}>RAG生成</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {newsItems.map((newsItem) => (
                      <TableRow key={newsItem.id}>
                        <TableCell sx={{ border: '1px solid black', padding: '8px', maxWidth: '250px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                          <a href={newsItem.link} target="_blank" rel="noopener noreferrer">
                            {newsItem.title}
                          </a>
                        </TableCell>
                        <TableCell sx={{ border: '1px solid black', padding: '8px' }}>
                          {newsItem.pubDate.toLocaleDateString("ja-JP", { month: "2-digit", day: "2-digit" })}
                        </TableCell>
                        <TableCell sx={{ border: '1px solid black', padding: '8px' }}>
                          <Button 
                            variant="contained" 
                            color="secondary" 
                            onClick={() => handleRAGGenerate(newsItem)}
                            disabled={isLoadingRAG} // RAG生成中はボタンを無効化
                          >
                            RAG生成
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          );
        })
      )}

      {/* S3リンク表示とダウンロード */}
      {s3Links.length > 0 && (
        <Box sx={{ mt: 4, textAlign: 'center' }} ref={logRef}>
          <Typography variant="h6">生成されたRAGデータリンク:</Typography>
          {s3Links.map((link, index) => (
            <Box key={index} sx={{ mt: 1 }}>
              <a href={link} target="_blank" rel="noopener noreferrer">リンク {index + 1}</a>
              <Button variant="outlined" sx={{ ml: 1 }} href={link} download={`RAG_data_${index + 1}`}>
                ダウンロード
              </Button>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};
