"use client";

import { User, onAuthStateChanged } from "firebase/auth";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { auth } from "../config/firebase";
import { Site, Outline,Article,Blog,ImageData } from '../types';
import { doc, getDoc } from "firebase/firestore";
import { db } from "../config/firebase";
import { useTranslation } from 'react-i18next';
// Firestoreからユーザーの記事作成回数と最終更新日時を取得する関数
const fetchUsageStats = async (userId: string) => {
  // 現在の年月を取得
  const now = new Date();
  const yearMonth = `${now.getFullYear()}-${now.getMonth() + 1}`; // 0を付けずに月を指定

  try {
    const usageStatsDocRef = doc(db, "users", userId, "usageStats", yearMonth);
    const docSnap = await getDoc(usageStatsDocRef);
    if (docSnap.exists()) {
      const { articleCount, lastUpdated } = docSnap.data();
      return { articleCount, lastUpdated };
    } else {
      // ドキュメントが存在しない場合はデフォルト値を返す
      return { articleCount: 0, lastUpdated: null };
    }
  } catch (error) {
    console.error("記事作成回数と最終更新日時の取得に失敗しました: ", error);
    throw error;
  }
};

type AppProviderProps = {
  children: ReactNode;
};

// AppContextType 型定義を更新
type AppContextType = {
  user: User | null;
  userId: string | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  values: {
    keyword: string;
    target: string;
    enKey: string;
    role: string;
    selectedTitle: string;
    selectedKeyword: string;
    articleCount: number; // 追加するフィールド articleCount
    currentLanguage:string, // ここに追加
    // selectedOutline: Outline|null;
    // selectedImage: ImageData | null;
    // selectedSite: Site | null;
    // wordCount: number;
  };
  // setValuesの型を更新
  setValues: React.Dispatch<React.SetStateAction<{
    keyword: string;
    target: string;
    enKey: string;
    role: string;
    selectedTitle: string;
    selectedKeyword: string;
    articleCount: number; // 追加するフィールド articleCount
    currentLanguage:string;
    // selectedOutline: Outline;
    // selectedImage: ImageData;
    // selectedSite: Site;
    // wordCount: number;
  }>>;

  
};

// setValuesの使用例
// 新しい状態を設定する際には、全てのフィールドを含むオブジェクトを渡すか、
// 現在の状態を基にした更新関数を渡すことができます。
// 例: setValues(prev => ({ ...prev, selectedTitle: '新しいタイトル' }));


// defaultContextData の初期化を更新
const defaultContextData: AppContextType = {
  user: null,
  userId: null,
  setUser: () => {},
  activeStep: 0,
  setActiveStep: () => {},
  values: {
    keyword: '',
    target: '',
    enKey: '',
    role: '',
    selectedTitle: '',
    selectedKeyword: '',
    articleCount: 0, // 追加するフィールド articleCount
    currentLanguage: 'ja'
    // selectedOutline: null, // 修正: 初期値は null で型は Outline | null
    // selectedImage: null,
    // selectedSite: null, // 修正: 初期値は null で型は Site | null
    // wordCount: 2000,
  },
  setValues: () => {}, // この関数でvaluesの状態を更新する際に期待される型に注意する
};




const AppContext = createContext<AppContextType>(defaultContextData);

export function AppProvider({ children }: AppProviderProps) {

  const [user, setUser] = useState<User | null>(null);
  const [userId, setUserId] = useState<string | null>(null);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());
  const [enKey, setEnKey] = useState<string>("");
  const [selectedImage, setSelectedImage] = useState<ImageData|null>(null);
  const [articleHTML, setArticleHTML] = useState<string>("");
  const [articleCount, setArticleCount] = useState<number>(0);
  const [selectedKeyword, setSelectedKeyword] = useState<string>("");
  const [selectedSite, setSelecteSite] = useState<Site|null>(null);
  const [target, setTarget] = useState<string>('');
  const [role, setRole] = useState<string>('');
  const [selectedTitle, setSelecteTitle] = useState<string>("");
  const [selectedOutline, setSelectedOutline] = useState<Outline | null>(null);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language; // 現在の言語情報を取得

  useEffect(() => {
    const updateLanguage = () => {
      setValues(prevValues => ({ ...prevValues, currentLanguage: i18n.language }));
    };
  
    i18n.on('languageChanged', updateLanguage);
  
    // コンポーネントがアンマウントされた時にイベントリスナーを解除
    return () => {
      i18n.off('languageChanged', updateLanguage);
    };
  }, [i18n]);

  
  // valuesの状態もContextで管理
  const [values, setValues] = useState({
    keyword: '',
    target: '',
    enKey: '',
    role: '',
    selectedTitle: '',
    selectedKeyword: '',
    articleCount: 0, // 追加するフィールド articleCount
    currentLanguage: 'ja', // ここに追加
    // selectedOutline: null, // 修正: 初期値は null で型は Outline | null
    // selectedImage: null,
    // selectedSite: null, // 修正: 初期値は null で型は Site | null
    // wordCount: 2000,
  });
  const contextValue = {
    activeStep,
    setActiveStep,
    values,
    setValues,
    user,
    userId,
    setUser,
    articleCount,
    setArticleCount,
    currentLanguage,
  };


  useEffect(() => {
    const fetchUserUsageStats = async () => {
      try {
        // userIdがnullの場合はデフォルトの値を返す
        if (!userId) {
          return { articleCount: 0, lastUpdated: null };
        }
        console.log(userId);
        const { articleCount, lastUpdated } = await fetchUsageStats(userId);
        console.log(articleCount);
        // 記事作成回数と最終更新日時をセット
        setValues(prev => ({
          ...prev,
          articleCount,
          lastUpdated,
        }));
      } catch (error) {
        console.error("ユーザーの記事作成回数と最終更新日時の取得に失敗しました: ", error);
      }
    };
  
    // ユーザーIDが変更された場合にのみ記事作成回数と最終更新日時を再取得
    if (userId) {
      fetchUserUsageStats();
    }
  }, [userId]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (newUser) => {
      setUser(newUser);
      setUserId(newUser ? newUser.uid : null);

      if (!newUser) {
        // navigate("/settings/login");
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <AppContext.Provider value={contextValue}>
      {children}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  return useContext(AppContext);
}