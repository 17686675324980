
import axios from 'axios';
import { Site, Outline,Article,Blog,ImageData,ArticleCreationData } from '../types';
import { createHtmlWithImage,generateArticleId } from "../utils/articleUtils"; // 適切なパスに修正してください
import { Firestore, doc, serverTimestamp, setDoc,getDoc } from 'firebase/firestore';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import React from 'react';
import { SUBSCRIPTION_PLANS } from "../config/constants";
import { addDoc, collection,  updateDoc } from "firebase/firestore";

// services/articleServices

export const saveArticleAndStats = async (
  db: Firestore,
  userId: string,
  articleMarkdown: string,
  inputKeyword: string,
  selectedKeyword: string,
  currentLanguage: string,
  category: string
) => {
  try {
    // 保存する記事データ
    const articleData = {
      userId: userId,
      articleMarkdown: articleMarkdown,
      inputKeyword: inputKeyword,
      selectedKeyword: selectedKeyword,
      currentLanguage: currentLanguage,
      category: category,
      createdAt: serverTimestamp()
    };

    // articlesコレクションに保存
    await addDoc(collection(db, "articles"), articleData);
    console.log("記事がarticlesコレクションに保存されました。");

    // 記事作成回数を記録する
    const currentDate = new Date();
    const yearMonth = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}`;
    const usageStatsDocRef = doc(db, "users", userId, "usageStats", yearMonth);

    const docSnap = await getDoc(usageStatsDocRef);
    if (docSnap.exists()) {
      await updateDoc(usageStatsDocRef, {
        articleCount: docSnap.data().articleCount + 1,
        lastUpdated: serverTimestamp(),
      });
    } else {
      await setDoc(usageStatsDocRef, {
        articleCount: 1,
        lastUpdated: serverTimestamp(),
      });
    }
    console.log("記事作成回数がusers/{userId}/usageStatsに記録されました。");
  } catch (error) {
    console.error("記事の保存に失敗しました:", error);
  }
};


export async function canCreateArticle(db: Firestore, userId: string, subscriptionId: string | null): Promise<boolean> {
  // subscriptionIdがない場合はfreeプラン扱い
  const plan = subscriptionId ? SUBSCRIPTION_PLANS[subscriptionId] : { name: "free", dailyLimit: 1 };
  
  if (!plan) {
    console.log("無効なサブスクリプションIDです:", subscriptionId);
    return false;
  }

  const currentDate = new Date();
  const yearMonth = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}`;
  const usageStatsDocRef = doc(db, "users", userId, "usageStats", yearMonth);

  try {
    const docSnap = await getDoc(usageStatsDocRef);
    const articleCount = docSnap.exists() ? docSnap.data().articleCount : 0;
    return articleCount < plan.dailyLimit;
  } catch (error) {
    console.error("制限確認エラー:", error);
    return false;
  }
}


export const postArticle = async (article: Article, selectedImage: ImageData, selectedBlog: Blog) => {



  if (!selectedBlog) return;
  const wordpressImageUrl = ""; // ここに画像アップロードロジックを実装

  const apiEndpoint = "https://o06j2dooq9.execute-api.ap-northeast-1.amazonaws.com/dev/wordpress-newpost-api";
  const htmlContent = createHtmlWithImage(article, selectedImage);
  try {
    const response = await axios.post(apiEndpoint, {
      url: selectedBlog.url,
      title: article.title,
      username: selectedBlog.entryId,
      password: selectedBlog.entryPw,
      draftFlag: selectedBlog.draftFlag,
      content: htmlContent
    });
  
    console.log("APIへの投稿成功:", response.data);
    // 成功後の処理をここに追加
  } catch (error) {
    console.error("APIへの投稿失敗:", error);
  }
};


export const researchCompetitorSite = async (inputKeyword:string) => {
  const [openModal, setOpenModal] = React.useState(false);
  const [modalMessage, setModalMessage] = React.useState('');

  const handleOpenModal = (message:string) => {
    setModalMessage(message);
    setOpenModal(true);
  };
  if (inputKeyword.trim()) {
    try {
      const response = await axios.get(`https://o06j2dooq9.execute-api.ap-northeast-1.amazonaws.com/dev/crawlee?q=${encodeURIComponent(inputKeyword)}`);
      const sites = response.data.items;
      console.log(sites);
      const extractedData = sites.map((site:Site) => ({
        title: site.title,
        link: site.link,
        snippet: site.snippet,
        image: site.pagemap?.cse_image?.[0]?.src, // サムネイル画像のURL
      }));
      return extractedData;
    } catch (error) {
      console.error(error);
      handleOpenModal('取得に失敗しました。再度「競合サイトの調査」をクリックしてください。');
      return []; // エラーが発生した場合は空の配列を返す
    }
  }
  return []; // キーワードが空の場合は空の配列を返す
};


export const saveOrUpdateArticleWithCustomId = async (db: Firestore, userId: string | null, articleData: ArticleCreationData) => {
  if(!userId) return;
  const articleId = generateArticleId(userId, articleData.selectedTitle);
  const docRef = doc(db, "articles", articleId);

  try {
    await setDoc(docRef, {
      ...articleData,
      userId: userId,
      createdAt: serverTimestamp(),
    }, { merge: true });
    console.log("Document saved or updated with custom ID: ", articleId);
  } catch (error) {
    console.error("Error saving or updating document: ", error);
  }
};