import React, { FC, useEffect, useRef, useState } from "react";
import { db } from "../config/firebase";
import {
  Timestamp,
  addDoc,
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  getDoc
} from "firebase/firestore";
import { useAppContext } from "../context/AppContext";
import OpenAI from "openai";
import { Box, Grid, TextField, IconButton, Typography, Paper } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

type Message = {
  text: string;
  sender: string;
  createdAt: Timestamp;
};

export const Chat: FC = () => {
  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_KEY,
    dangerouslyAllowBrowser: true,
  });

  const { userId } = useAppContext();
  const [inputMessage, setInputMessage] = useState<string>("");
  const [messages, setMessages] = useState<Message[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const scrollDiv = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (userId) {
      const fetchMessages = async () => {
        const userRoomRef = doc(db, "rooms", userId);
        const userRoomSnap = await getDoc(userRoomRef);

        if (!userRoomSnap.exists()) {
          await setDoc(userRoomRef, {
            userId: userId,
            createdAt: serverTimestamp(),
          });
        }

        const messagesRef = collection(userRoomRef, "messages");
        const q = query(messagesRef, orderBy("createdAt"));

        const unsubscribe = onSnapshot(q, (snapshot) => {
          const newMessages = snapshot.docs.map((doc) => doc.data() as Message);
          setMessages(newMessages);

          // スクロールを更新
          if (scrollDiv.current) {
            scrollDiv.current.scrollTo({
              top: scrollDiv.current.scrollHeight,
              behavior: "smooth",
            });
          }
        });

        return () => {
          unsubscribe();
        };
      };

      fetchMessages();
    }
  }, [userId]);

  const sendMessage = async () => {
    if (!inputMessage.trim() || !userId) return;

    const messageData = {
      text: inputMessage,
      sender: "user",
      createdAt: serverTimestamp(),
    };

    try {
      const userRoomRef = doc(db, "rooms", userId);
      const messagesRef = collection(userRoomRef, "messages");
      await addDoc(messagesRef, messageData);

      setInputMessage("");
      setIsLoading(true);

      const gpt3Response = await openai.chat.completions.create({
        model: "gpt-4o-mini",
        messages: [
          { role: "system", content: "ChatBot" },
          { role: "user", content: inputMessage },
        ],
      });

      setIsLoading(false);

      const botResponse = gpt3Response.choices[0]?.message?.content || "";
      await addDoc(messagesRef, {
        text: botResponse,
        sender: "bot",
        createdAt: serverTimestamp(),
      });
    } catch (error) {
      console.error("Error sending message:", error);
      setIsLoading(false);
    }
  };

  return (
    <Grid container component={Paper} style={{ height: "80vh", overflow: "hidden" }}>
      <Grid item xs={12} style={{ display: "flex", flexDirection: "column" }}>
        <Box style={{ padding: "10px", borderBottom: "1px solid #ddd" }}>
          <Typography variant="h6">Chat Room</Typography>
        </Box>

        <Box
  ref={scrollDiv}
  style={{
    flexGrow: 1,
    overflowY: "auto", // スクロールバーを表示
    padding: "10px",
    height: "calc(100vh - 330px)", // コンテナの高さを調整
  }}
>
  {messages.map((message, index) => (
    <Box
      key={index}
      style={{
        display: "flex",
        justifyContent: message.sender === "user" ? "flex-end" : "flex-start",
        marginBottom: "10px",
      }}
    >
      <Typography
        style={{
          backgroundColor: message.sender === "user" ? "#cfe9ff" : "#f0f0f0",
          padding: "10px",
          borderRadius: "10px",
          maxWidth: message.sender === "user" ? "60%" : "100%",
        }}
>
        {message.text}
      </Typography>
    </Box>
          ))}
          {isLoading && <Typography>Loading...</Typography>}
        </Box>


        <Box
          style={{
            display: "flex",
            padding: "10px",
            borderTop: "1px solid #ddd",
            alignItems: "center",
          }}
        >
          <TextField
            fullWidth
            variant="outlined"
            placeholder="メッセージを入力してください..."
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") sendMessage();
            }}
          />
          <IconButton color="primary" onClick={sendMessage}>
            <SendIcon />
          </IconButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Chat;
