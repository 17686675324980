import React, { FC, useState } from "react";
import { Box, CircularProgress, ImageList, ImageListItem, Button, Dialog, DialogContent, DialogTitle, IconButton, Typography, Grid, FormGroup, Stack, TextField } from "@mui/material";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DownloadIcon from '@mui/icons-material/Download';
import axios from "axios";
import useFetchImages from "../hooks/useFetchImages"; // useFetchImagesをインポート

interface ImageData {
  mediaURL: string;
  tags: string;
  displayLink?: string;
}

export const ImageSearchPage: FC = () => {
  const [googleImages, setGoogleImages] = useState<ImageData[]>([]);
  const [inputKeyword, setInputKeyword] = useState<string>("");
  const [inputError, setInputError] = useState<string>("");
  const [start, setStart] = useState(1);
  const [hasMoreGoogle, setHasMoreGoogle] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<ImageData | null>(null);
  const [isLoading, setIsLoading] = useState(false); // ローディング状態の追加

  // useFetchImagesフックでPixabay画像を取得
  const { images: pixabayImages, isLoading: isLoadingPixabay, loadMoreImages, hasMore: hasMorePixabay } = useFetchImages(inputKeyword);

  // Google Imagesの取得関数
  const fetchGoogleImages = async (keyword: string, startPosition: number = 1) => {
    if (keyword.trim()) {
      setIsLoading(true); // ローディング開始
      try {
        const response = await axios.get(
          `https://wb1wp5h089.execute-api.ap-northeast-1.amazonaws.com/dev/search-api?q=${encodeURIComponent(keyword)}&type=image&start=${startPosition}`
        );

        const sites = response.data.items || [];
        const extractedData = sites.map((site: any) => ({
          mediaURL: site.link || "images/noimage-760x460.png",
          tags: site.title || "No title",
          displayLink: site.displayLink || "Unknown source"
        }));

        if (startPosition > 1) {
          setGoogleImages((prevImages) => [...prevImages, ...extractedData]);
        } else {
          setGoogleImages(extractedData); // 再検索時は新しいデータに置き換え
        }

        setHasMoreGoogle(sites.length === 10);
      } catch (error) {
        console.error("Error fetching Google images:", error);
      } finally {
        setIsLoading(false); // ローディング終了
      }
    }
  };

  // 画像クリック時のポップアップ表示
  const handleImageClick = (image: ImageData) => {
    setSelectedImage(image);
    setOpen(true);
  };

  // ポップアップを閉じる
  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null);
  };

  // 検索ボタンクリック時のイベント
  const handleKeywordSubmit = () => {
    if (!inputKeyword.trim()) {
      setInputError("キーワードを入力してください");
      return;
    }
    setGoogleImages([]); // Google画像リセット
    setStart(1); // スタート位置リセット
    setHasMoreGoogle(false);
    setIsLoading(true); // ローディング開始
    fetchGoogleImages(inputKeyword, 1); // Google画像を取得
    loadMoreImages(); // Pixabay画像をロード
  };

  const handleLoadMore = () => {
    const nextStart = start + 10;
    setStart(nextStart);
    fetchGoogleImages(inputKeyword, nextStart); // Googleの次の10件取得
    loadMoreImages(); // Pixabayの次のページ取得
  };

  return (
    <Box>
      {/* キーワード検索フォーム */}
      <Grid item xs={12} lg={12}>
        <FormGroup>
          <Box sx={{ mb: 3, p: 2, backgroundColor: "#e3f2fd", borderRadius: 2 }}>
            <Typography variant="h6">
              キーワードからのイメージ収集
            </Typography>
            <Typography variant="body1">
              このページでは、キーワードから関連するイメージをPixabay（フリー素材）およびGoogle画像検索から取得できます。
              複数のサービスを横断検索できるので、手間が省け、必要な画像を素早く見つけることができます。
            </Typography>
          </Box>
          <TextField 
            variant="outlined" 
            margin="normal"
            fullWidth 
            label="キーワード"
            value={inputKeyword}
            onChange={(e) => {
              setInputKeyword(e.target.value);
              setInputError(""); // エラーメッセージをリセット
            }}
            error={inputError !== ''}
            helperText={inputError}
          />
          <Button variant="contained" color="primary" fullWidth onClick={handleKeywordSubmit} disabled={isLoading}>
            <ArrowDownwardIcon/>
            キーワード検索
            <ArrowDownwardIcon/>
          </Button>
        </FormGroup>
      </Grid>

      {/* Pixabay 画像表示 */}
      {pixabayImages.length > 0 && (
        <Box>
          <Stack my={2} direction="row" justifyContent="center" spacing={10}>
            <Typography variant="h5">Pixabay (無料)</Typography>
          </Stack>
          <ImageList sx={{ width: "auto" }} cols={5} rowHeight={300}>
            {pixabayImages.map((image, index) => (
              <ImageListItem key={index} onClick={() => handleImageClick(image)} sx={{ cursor: "pointer" }}>
                <img
                  src={image.mediaURL}
                  alt={image.tags}
                  loading="lazy"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Box>
      )}

      {/* Google Image Search 表示 */}
      {googleImages.length > 0 && (
        <Box>
          <Stack my={2} direction="row" justifyContent="center" spacing={10}>
            <Typography variant="h5">Google画像検索 (有料)</Typography>
          </Stack>
          <ImageList sx={{ width: "auto" }} cols={5} rowHeight={300}>
            {googleImages.map((image, index) => (
              <ImageListItem key={index} onClick={() => handleImageClick(image)} sx={{ cursor: "pointer" }}>
                <img
                  src={image.mediaURL}
                  alt={image.tags}
                  loading="lazy"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </ImageListItem>
            ))}
          </ImageList>

          {/* もっと見るボタン */}
          {(hasMoreGoogle || hasMorePixabay) && (
            <Box sx={{ textAlign: "center", mt: 2 }}>
              <Button variant="contained" color="primary" onClick={handleLoadMore} disabled={isLoading || isLoadingPixabay}>
                もっと見る
              </Button>
            </Box>
          )}
        </Box>
      )}

      {/* ポップアップ表示 */}
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>画像プレビュー</DialogTitle>
        <DialogContent sx={{ textAlign: "center" }}>
          {selectedImage && (
            <>
              <img src={selectedImage.mediaURL} alt={selectedImage.tags} style={{ maxWidth: "100%", maxHeight: "80vh" }} />
              <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                引用元: {selectedImage.displayLink || "Pixabay"}
              </Typography>
              <Button
                href={selectedImage.mediaURL}
                download={`${selectedImage.tags}.jpg`}
                variant="contained"
                color="primary"
                startIcon={<DownloadIcon />}
                sx={{ mt: 2 }}
              >
                ダウンロード
              </Button>
            </>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};
