import { useEffect, useState, FC, ChangeEvent } from "react";
import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { Box, Typography, Avatar, List, ListItem, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { Link } from "react-router-dom";
import { useAppContext } from "../context/AppContext";
import { db } from "../config/firebase";
import { useTranslation } from "react-i18next";

type Blog = {
  id: string;
  url: string;
  title: string;
  draftFlag: boolean;
};

export const BlogListPage: FC = () => {
  const { t } = useTranslation();
  const { userId } = useAppContext();
  const [blogs, setBlogs] = useState<Blog[]>([]);

  useEffect(() => {
    if (userId) {
      const fetchBlogs = async () => {
        const blogCollectionRef = collection(db, "blogs");
        const q = query(blogCollectionRef, where("userId", "==", userId), orderBy("createdAt"));
        const unsubscribe = onSnapshot(q, (snapshot) => {
          const newBlogs: Blog[] = snapshot.docs.map((doc) => ({
            id: doc.id,
            url: doc.data().url,
            title: doc.data().title,
            draftFlag: doc.data().draftFlag,
          }));
          setBlogs(newBlogs);
        });
        return () => unsubscribe();
      };
      fetchBlogs();
    }
  }, [userId]);

  if (blogs.length === 0) {
    return (
      <Box component="section" sx={{ p: 5, border: "1px dashed grey" }}>
        <Typography variant="h6" sx={{ textAlign: "center" }}>
          {t("no_registered_blogs")}
        </Typography>
        <Typography variant="body1" sx={{ textAlign: "center" }}>
          <Link to="/blog/new">{t("register_your_blog")}</Link>
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ width: "80%", m: "auto", p: 3 }}>
      <Typography variant="h5" sx={{ mb: 3 }}>
        {t("your_blogs")}
      </Typography>
      <List>
        {blogs.map((blog) => (
          <ListItem key={blog.id} sx={{ display: "flex", alignItems: "center", borderBottom: "1px solid grey" }}>
            <Avatar alt={blog.title} src={`http://capture.heartrails.com/150x100/cool?${encodeURIComponent(blog.url)}`} />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle1">{blog.title}</Typography>
              <Typography variant="body2" color="text.secondary">
                {blog.url}
              </Typography>
              <Typography variant="body2" sx={{ color: blog.draftFlag ? "error.main" : "success.main" }}>
                {blog.draftFlag ? t("draft") : t("post")}
              </Typography>
            </Box>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};
