import { FC, useEffect } from "react";
import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Step1Form from '../pages/StepTrend/Step1Form';
import Step2Form from '../pages/StepTrend/Step2Form'; 
import Step3Form from '../pages/StepTrend/Step3Form'; 
import axios from "axios";
import OpenAI from "openai";
import useTemplate from '../hooks/useTemplate'; // パスはプロジェクト構成に応じて調整してください
import useFetchImages from '../hooks/useFetchImages'; // パスはプロジェクト構成に応じて調整してください
import { Site, Outline,Article,Blog,ImageData,ResearchSite,ArticleCreationData,Values,News } from '../types';
import { db } from "../config/firebase";
import AiSelector from '../components/AiSelector'; 
import LanguageSwitcher from '../components/LanguageSwitcher';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import {
  Firestore,
  Timestamp,
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";

import { useAppContext } from "../context/AppContext";
import { createHtmlWithImage,fetchFromAI,extractJsonFromTextEnhanced } from "../utils/articleUtils";
import { postArticle, saveOrUpdateArticleWithCustomId } from "../services/articleServices";
import { FormControl, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";



export const ArticleTrendNewPage: FC = () => {



  
  const { t, i18n } = useTranslation();
  const steps = [t('keywords'), t('outline'), t('article_generation')];
  
  // const [selectedBlog, setSelectedBlog] = React.useState<Blog | null>(null);
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const [inputKeyword, setInputKeyword] = React.useState<string>("");
  const [enKey, setEnKey] = React.useState<string>("");
  const [category, setCategory] = React.useState<string>("");
  const [selectedImage, setSelectedImage] = React.useState<ImageData|null>(null);
  const [currentLanguage, setCurrentLanguage] = React.useState(i18n.language);

  const [suggestKeywords, setSuggestKeywords] = React.useState<Suggestion[]>([]);
  const [sites, setSites] = React.useState<Site[]>([]);
  const [news, setNews] = React.useState<News[]>([]);
  const [selectedNews, setSelectedNews] = React.useState<News[]>([]);
  const [article, setArticle] = React.useState<Article | null>(null);
  const [articleHTML, setArticleHTML] = React.useState<string>("");
  const [articleMarkdown, setArticleMarkdown] = React.useState<string>("");
  const [selectedKeyword, setSelectedKeyword] = React.useState<string>("");
  // const [selectedSite, setSelectedSite] = React.useState<string>("");
  const [selectedSites, setSelectedSites] = React.useState<Site[]>([]);
  const [selectedSitesStr, setSelectedSitesStr] = React.useState<string>("");
  const [selectedNewsStr, setSelectedNewsStr] = React.useState<string>("");
  const [target, setTarget] = React.useState<string>(t('all'));
  const [role, setRole] = React.useState<string>(t('expert'));
  const [imageData, setImageData] = React.useState<ImageData[]>([]);
  const [researchSites, setResearchSites] =  React.useState<ResearchSite[]>([]);

  const [isLoadingTitles, setIsLoadingTitles] = React.useState(false);
  const [isLoadingSites, setIsLoadingSites] = React.useState(false);
  const [isLoadingKeywords, setIsLoadingKeywords] = React.useState(false);
  const [isLoadingOutlines, setIsLoadingOutlines] = React.useState(false);
  const [isLoadingArticle, setIsLoadingArticle] = React.useState(false);
  const [isLoadingNews, setIsLoadingNews] = React.useState(false);
  const [isLoadingCrawling, setIsLoadingCrawling] = React.useState(false);

  const [suggestTitles, setSuggestTitles] = React.useState<string[]>([]);
  const [suggestOutlines, setSuggestOutlines] = React.useState<Outline[]>([]);
  const [selectedTitle, setSelectedTitle] = React.useState<string>("");
  const [selectedOutline, setSelectedOutline] = React.useState<string | null>(null);
  const [selectedImages, setSelectedImages] = React.useState<ImageData[]>([]);
  // const [selectedAI, setSelectedAI] = React.useState<string>("gpt-3.5-turbo");
  const [selectedAI, setSelectedAI] = React.useState<string>("gpt-4o-mini");
  
  const SuggestArticleTemplate = useTemplate('/templates/suggestArticle.txt'); 
  const SuggestOutlineTemplate = useTemplate('/templates/suggestOutlines.txt'); 
  const SuggestKeywordsTemplate = useTemplate('/templates/suggestKeywords.txt');
  const SuggestTitlesTemplate = useTemplate('/templates/suggestTitles.txt');

  const { user, userId } = useAppContext();
  const [openModal, setOpenModal] = React.useState(false);
  const [modalMessage, setModalMessage] = React.useState('');

  const [temporaryFiles, setTemporaryFiles] = React.useState<string[]>([]);
  const [allNews, setAllNews] = React.useState<NewsItem[]>([]); // 型をNewsItem[]に変更


const [selectedAllNews, setSelectedAllNews] = React.useState<NewsItem[]>([]); // 選択されたニュースを管理

  // Suggestion 型を定義
  type Suggestion = {
    keyword: string;       // 複合キーワード
    competition: string;   // 競合度
    searchVolume: string;  // 検索ボリューム
    keywordType: string;
  };
  // NewsItem 型を定義
  type NewsItem = {
    id: string;
    title: string;
    description: string;
    link: string;
    pubDate: Date;
  };

  useEffect(() => {
    const fetchAllNews = async () => {
      const newsCollectionRef = collection(db, "news");
      const q = query(newsCollectionRef, orderBy("pubDate", "desc"));
      const snapshot = await getDocs(q);

      const newsList: NewsItem[] = snapshot.docs.map((doc) => ({
        id: doc.id,
        title: doc.data().title,
        description: doc.data().description,
        link: doc.data().link,
        pubDate: doc.data().pubDate.toDate(), // Date型に変換
      }));
      setAllNews(newsList); // 型が一致するように設定
    };

    fetchAllNews();
  }, []);

  // const { images, isLoading: isLoadingImages } = useFetchImages(inputKeyword);
  useEffect(() => {
    // `selectedSites` の変更を監視して、`selectedSitesStr` を更新
    const newSelectedSitesStr = selectedSites.map(site => `${site.title}${site.snippet}`).join(', ');
    setSelectedSitesStr(newSelectedSitesStr);
  }, [selectedSites]); // `selectedSites` が変更されたときに実行

  useEffect(() => {
    // 言語変更時のイベントリスナーを登録
    const onLanguageChanged = (lng: string) => {
      setCurrentLanguage(lng);
    };

    i18n.on('languageChanged', onLanguageChanged);

    // コンポーネントのクリーンアップ時にイベントリスナーを解除
    return () => {
      i18n.off('languageChanged', onLanguageChanged);
    };
  }, [i18n]);
  

    // 状態更新が完了してから記事提案の関数を呼び出す
    useEffect(() => {
      if (researchSites.length > 0) {
        suggestArticle();
      }
    }, [researchSites]); // researchSitesが更新されたときに実行

    useEffect(() => {
      if (userId) recordArticleCreation(userId); // 記事作成の記録
    }, [articleHTML]); // researchSitesが更新されたときに実行


// ユーザー活動データの型定義
interface UserActivity {
  userId: string;
  activityType: string; // 活動タイプ
  timestamp?: typeof serverTimestamp; // 時間
}

const values = {
  keyword: inputKeyword,
  target: target,
  role: role,
  enKey:enKey,
  category: category,
  article: article,
  articleHTML: articleHTML,
  selectedTitle:selectedTitle,
  selectedKeyword:selectedKeyword,
  selectedOutline:selectedOutline,
  selectedSites: selectedSites,
  selectedSitesStr: selectedSitesStr,
  selectedNewsStr: selectedNewsStr,
  wordCount:2000,
  selectedSite: selectedSites, // 適切な Site オブジェクトを設定
  ai:selectedAI,
  currentLanguage:currentLanguage,
  researchSites:researchSites,
};

  const handleOpenModal = (message:string) => {
    setModalMessage(message);
    setOpenModal(true);
  };

// 記事作成回数を記録する関数
const recordArticleCreation = async (userId: string) => {
  const currentDate = new Date();
  const yearMonth = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}`;
  const usageStatsDocRef = doc(db, "users", userId, "usageStats", yearMonth);

  try {
    const docSnap = await getDoc(usageStatsDocRef);
    // console.log(docSnap);
    if (docSnap.exists()) {
      // ドキュメントが存在する場合、記事作成回数をインクリメント
      await updateDoc(usageStatsDocRef, {
        articleCount: docSnap.data().articleCount + 1,
        lastUpdated: serverTimestamp(),
      });
    } else {
      // ドキュメントが存在しない場合、新しく作成
      await setDoc(usageStatsDocRef, {
        articleCount: 1,
        lastUpdated: serverTimestamp(),
      });
    }
    console.log("記事作成回数を記録しました。");
  } catch (error) {
    console.error("記事作成回数の記録に失敗しました: ", error);
  }
};

  // Firestoreにユーザー活動を保存する関数
  const saveUserActivity = async (activity: UserActivity, userId: string) => {
    try {
      const docRef = await addDoc(collection(db, "userStatus"), {
        ...activity,
        userId: userId,
        timestamp: serverTimestamp(), // 時間
      });
      console.log("Activity logged with ID: ", docRef.id);
    } catch (e) {
      console.error("Error logging activity: ", e);
    }
  };





  const gotoStep2 = async () => {
    console.log(selectedSites);
    setSuggestTitles([]); // 関連ワードリストをクリア
    setSelectedSitesStr(
      selectedSites.map(site => site.title+site.snippet).join(', ')
    );
    // `setEnKey` と `setCategory` の設定を Promise として扱うための例
    await new Promise((resolve) => {
      setEnKey(enKey => {
        resolve(enKey); // Promise が解決されるのを待つ
        return enKey;
      });
    });

    await new Promise((resolve) => {
      setCategory(category => {
        resolve(category); // Promise が解決されるのを待つ
        return category;
      });
    });
    await new Promise((resolve) => {
      setSelectedSitesStr(selectedSites.map(site => site.title + site.snippet).join(', '));
      resolve(selectedSitesStr);
    });
    console.log(selectedSitesStr);
    console.log(category);
    // 次のステップに進む
    handleNextStep();
    await suggestTitle();
    // await suggestTitle();
  };

  const gotoStep3 = async () => {
    setArticle(null); // 記事の状態をリセット
    setArticleHTML(""); // 記事HTMLの状態をリセット

    console.log(selectedSites); // デバッグ用のログ
    setSelectedSitesStr(selectedSites.map(site => site.title + site.snippet).join(', ')); // 選択されたサイトの文字列を設定

    console.log(selectedSitesStr); // デバッグ用のログ
    setActiveStep(1); // 次のステップに進む
    handleNextStep(); // 次のステップの処理を呼び出す


    // 非同期処理でresearchSitesを取得し、結果に基づいて処理を行う
    const s3urls= await researchCompetitorSite(selectedSites);
    console.log(s3urls); // デバッグ用のログ

    if (s3urls) {
        // S3 URLからコンテンツを取得してステートに格納
        const fetchedData = await fetchContentFromUrls(s3urls);
        console.log(fetchedData);
        setResearchSites(fetchedData); // ステート更新


        await suggestArticle(); // suggestArticle関数を呼び出して記事の提案を取得
        // if (userId) recordArticleCreation(userId); // 記事作成の記録
    }

 

};



  // ステップを進める関数
  const handleNextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const researchCompetitorSite = async (selectedSites:Site[]) => {
    setIsLoadingCrawling(true); // ローディング開始
    if (selectedSites.length > 0) {
      // 選択されたサイトのURLを取得し、カンマ区切りの文字列にする
      const urls = selectedSites.map(site => site.link).join(',');
      console.log(selectedSites);
      // URLをエンコード
      const encodedUrls = encodeURIComponent(urls);
      
      try {
        // Lambda関数のエンドポイントにリクエストを送る
        const response = await axios.get(`https://wb1wp5h089.execute-api.ap-northeast-1.amazonaws.com/dev/crawlee?urls=${encodedUrls}`);
        
        // Lambdaから返されたデータを取得
        const s3Urls = response.data;
        console.log("S3 URLs: ", s3Urls);

        return s3Urls;
        
      } catch (error) {
        console.error("Error fetching competitor site data:", error);
      }finally {
        setIsLoadingCrawling(false); // ローディング終了
      }

    }
  };

// S3 URLが有効かどうかをチェックし、データを取得する関数
const fetchContentFromUrls = async (urls:string[]) => {
  const checkInterval = 5000; // チェック間隔を5秒に設定
  const maxAttempts = 12; // 最大試行回数を12回に設定（1分間）

  // S3 URLが有効になるのを待つための関数
  const waitForFile:any = async (url:string, attempts = 1) => {
    try {
      const response = await axios.get(url);
      // ステータスコード200が返ってきたらファイルが準備完了と見なす
      if (response.status === 200) {
        console.log(`File is ready at ${url}`);
        return response.data;
      }
      throw new Error('File not ready');
    } catch (error) {
      if (attempts < maxAttempts) {
        console.log(`Waiting for file... attempt ${attempts + 1}`);
        // ファイルが準備できていない場合は指定した間隔で再試行
        return new Promise((resolve) => setTimeout(() => resolve(waitForFile(url, attempts + 1)), checkInterval));
      }
      throw new Error(`File at ${url} not ready after several attempts: ${error}`);
    }
  };
  // 各URLに対してwaitForFileを呼び出し、すべてのファイルが準備完了するのを待つ
  return Promise.all(urls.map(url => waitForFile(url)));
};
const surveySEO  = async () => {
  setSuggestKeywords([]); // 関連ワードリストをクリア
  setSites([]); // 関連サイトリストをクリア
  setNews([]); // 関連サイトリストをクリア
  await suggestKeyword();
  // await researchCompetitorSite();
}
  
  // suggestTitle 関数の定義
  const suggestTitle = async () => {

    if (selectedKeyword.trim() && SuggestTitlesTemplate) {
      setIsLoadingTitles(true); // ローディング開始
      try {
        const content = await fetchFromAI (SuggestTitlesTemplate,values);
        if (content && content.length>0 ){
          console.log(content);
          const jsonObject = JSON.parse(content);

          const suggestTitles= jsonObject.items;
          setSuggestTitles(suggestTitles);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoadingTitles(false); 
      }
    }
    return true;
  };

  // suggestKeyword 関数の定義
  const suggestKeyword = async () => {
    if (inputKeyword.trim() && SuggestKeywordsTemplate) {
      setIsLoadingKeywords(true); // ローディング開始
      try {
        console.log(values);
        const content = await fetchFromAI(SuggestKeywordsTemplate,values);
        if (content && content.length>0 ){
          console.log(content);
          const jsonObject = extractJsonFromTextEnhanced(content);
          // const jsonObject = JSON.parse(content);

          // itemsプロパティにアクセスして配列を取得
          const enKey = jsonObject.keywordInEnglish;
          setEnKey(enKey);
          values.enKey = enKey;
          const category = jsonObject.Category;
          // console.log(category);
          setCategory(category);
  
          values.category = category;
          const suggestKeywords = jsonObject.items;
          setSuggestKeywords(suggestKeywords);
          console.log('suggestKeywords:', suggestKeywords);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoadingKeywords(false); // ローディング終了
      }
    }
    return true;
  };

  const suggestArticle = async () => {
    values.wordCount = 3000;
    // values.selectedOutline = selectedOutline;
    setIsLoadingArticle(true); // ローディング開始
    try {
      // const content = await fetchFromOpenAI(SuggestArticleTemplate,values);
      const content = await fetchFromAI(SuggestArticleTemplate,values);
      // console.log(content);
      if (content){
        // console.log(content);
        const titleMatch = content.match(/^# (.+)/);
        // console.log(titleMatch);
        const title = titleMatch ? titleMatch[1] : 'No Title';
        console.log(title);
          const articleHTML =createHtmlWithImage(content,selectedImages);
          setSelectedTitle(title);
          setSelectedImage(selectedImages[0]);
          setArticleHTML(articleHTML.html);
          setArticleMarkdown(content);
          console.log(values);
          // saveArticleToFirestore();
          // saveOrUpdateArticleWithCustomId(db,userId,values);
          if (selectedImages[0] && selectedImages[0].mediaURL) {
            console.log(values);
            await saveArticleToFirestore(selectedImages[0]);
            console.log("記事がFirebase Firestoreに保存されました。");
          }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingArticle(false); // ローディング終了
    }
  };


// 記事をFirebase Firestoreに保存する関数
const saveArticleToFirestore = async (selectedImage:ImageData) => {
  try {
    console.log(selectedImage);
    // 'articles' コレクションに新しいドキュメントを追加
    await addDoc(collection(db, "articles"), {
      articleHTML: articleHTML,
      keyword: inputKeyword,
      selectedKeyword: selectedKeyword,
      selectedOutline: selectedOutline,
      selectedTitle: selectedTitle,
      ai: selectedAI,
      role: role,
      target: target,
      userId: userId,
      selectedImage: selectedImage?.mediaURL, // 選択した画像のURLを保存
      createdAt: serverTimestamp() // 作成時刻
    });
    console.log("記事がFirebase Firestoreに保存されました。");
  } catch (error) {
    console.error("記事の保存に失敗しました: ", error);
  }
};


  const suggestOutline = async () => {
    setSuggestOutlines([]); 
    if ( selectedTitle.trim() && SuggestKeywordsTemplate) {
      setIsLoadingOutlines(true); // ローディング開始
      try {
        const content = await fetchFromAI(SuggestOutlineTemplate,values);
        if (content && content.length>0 ){
          // JSON文字列をオブジェクトに変換
          const jsonObject = JSON.parse(content);
          if (jsonObject && jsonObject.outlines) {
            // itemsプロパティにアクセスして配列を取得
            const itemsArray = jsonObject.outlines;
            console.log(itemsArray);
            console.log("Current userId:", userId);
            setSuggestOutlines(itemsArray);
            // saveOrUpdateArticleWithCustomId(db,userId,values);
          }else{
            console.error('応答にoutlinesプロパティが含まれていません。');
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoadingOutlines(false);
      }
    }
  };

  
  const isStepOptional = (step: number) => {
    return step === 1;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
      // ここに、ステップに応じた状態の更新ロジックを追加できます。
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  useEffect(() => {
    if(article && selectedImage) {
      const updatedHTML = createHtmlWithImage(article,selectedImages);
      setArticleHTML(updatedHTML.html);
    }
  }, [selectedImage, article]);

  useEffect(() => {
    // enKey または category が更新された後に行いたい処理
    console.log("enKey or category updated:", enKey, category);
  
    // 必要なら values オブジェクトをここで更新し、関数に渡す
    const updatedValues = { ...values, enKey, category };
    // suggestTitle(updatedValues); // 例として suggestTitle 関数を呼び出す場合
  }, [enKey, category]); // enKey と category を依存配列に追加


  
  
// ステップごとのコンテンツ
const stepContents = [
  <Step1Form
    key="step1"
    inputKeyword={inputKeyword}
    setInputKeyword={setInputKeyword}
    isLoadingKeywords={isLoadingKeywords}
    isLoadingSites={isLoadingSites}
    onSubmitKeyword={surveySEO}
    gotoStep2={gotoStep2}
    gotoStep3={gotoStep3}
    setIsLoadingNews={setIsLoadingNews}
    isLoadingNews={isLoadingNews}
    suggestions={suggestKeywords} 
    setSelectedKeyword={setSelectedKeyword}
    selectedKeyword={selectedKeyword}
    setSelectedSitesStr={setSelectedSitesStr}
    setSelectedNewsStr={setSelectedNewsStr}
    setTarget={setTarget}
    setRole={setRole}
    sites={sites}
    setSites={setSites}
    selectedSites={selectedSites}
    setSelectedSites={setSelectedSites}
    currentLanguage={currentLanguage}
    news={news}
    setNews={setNews}
    selectedNews={selectedNews}
    setSelectedNews={setSelectedNews}
    target={target}
    role={role}
      // 新たに追加するプロパティ
    allNews={allNews} // ニュース一覧
    selectedAllNews={selectedAllNews} // 選択されたニュース
    setSelectedAllNews={setSelectedAllNews} // ニュース選択のsetter
  />,
  <Step2Form
    key="step2"
    selectedKeyword={selectedKeyword} 
    selectedSites={selectedSites}
    selectedSitesStr={selectedSitesStr}
    selectedTitle={selectedTitle}
    category={category}
    isLoadingTitles={isLoadingTitles}
    isLoadingOutlines={isLoadingOutlines}
    onSubmitSelectedTitle={suggestOutline}
    suggestTitles={suggestTitles}
    setSelectedTitle={setSelectedTitle} 
    suggestOutlines={suggestOutlines}
    selectedOutline={selectedOutline}
    setSelectedOutline={setSelectedOutline}
    onSubmitSelectedOutline={gotoStep3} 
  />,
  <Step3Form
    key="step3"
    inputKeyword={inputKeyword}
    article={article}
    setArticle={setArticle}
    setInputKeyword={setInputKeyword}
    isLoadingArticle={isLoadingArticle}
    onSubmitKeyword={suggestKeyword}
    suggestions={suggestKeywords} 
    // rewriteArticle={rewriteArticle}
    imageData={imageData}
    values={values}
    enKey={enKey}
    suggestArticle={suggestArticle}
    selectedImage={selectedImage}
    // selectedBlog={selectedBlog}
    // setSelectedBlog={setSelectedBlog}
    setSelectedImage={setSelectedImage}
    setSelectedImages={setSelectedImages}
    selectedImages={selectedImages}
    articleHTML={articleHTML}
    setArticleHTML={setArticleHTML}
    isLoadingCrawling={isLoadingCrawling}
    setIsLoadingCrawling={setIsLoadingCrawling}
  />,
];
  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};

          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Stack my={2} direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
          <Typography  variant="h5" >Step {activeStep + 1}</Typography>
          <Box sx={{ minWidth: 120 }}>
          {/* <BorderColorIcon/> */}
            <FormControl fullWidth>
              <InputLabel 
                id="ai-selector-label" 
              >
                AI Model
              </InputLabel>

              
              <Select
                labelId="ai-selector-label"
                id="ai-selector"
                value={selectedAI}
                label="AI Model"
                onChange={(e) => setSelectedAI(e.target.value)}
                size="small" 
              >

                <MenuItem value="gpt-4o">GPT-4o</MenuItem>
                <MenuItem value="gpt-4o-mini">GPT-4o mini</MenuItem>
                {/* <MenuItem value="claude">Claude</MenuItem> */}
                <MenuItem value="claude-sonnet">Claude(sssonnet)</MenuItem>
                <MenuItem value="claude-opus">Claude(ssopus)</MenuItem>
                model_opus

              </Select>
            </FormControl>
          </Box>
          </Stack>
          <div>{stepContents[activeStep]}</div>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            {activeStep > 0 && (
              <Button onClick={handleBack} sx={{ mr: 1 }}>
                Back
              </Button>
            )}
            <Box sx={{ flex: '1 1 auto' }} />
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
};
