import React, { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { auth } from "../config/firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import { Link as RouterLink } from "react-router-dom";
import { Button, TextField, Typography, Grid, Paper, Container, Link } from '@mui/material';
import { useTranslation } from "react-i18next";

type Inputs = {
  email: string;
};

const ForgotPassword = () => {
  const [message, setMessage] = useState<string | null>(null);
  const { register, handleSubmit, formState: { errors } } = useForm<Inputs>();
  const { t } = useTranslation();
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      await sendPasswordResetEmail(auth, data.email);
      setMessage(t('password_reset_email_sent'));
    } catch (error: any) {
      setMessage(t('send_email') + error.message);
    }
  };

  return (
    <Container component="main" maxWidth="xs" sx={{ pt: 8, pb: 6 }}>
      <Paper elevation={3} sx={{ p: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h5" gutterBottom>
          {t('forgot_password')}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%', marginTop: 1 }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label={t('email_address')}
            autoComplete="email"
            autoFocus
            {...register("email", { required: t('email_is_required') })}
            error={Boolean(errors.email)}
            helperText={errors.email?.message}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
          >
            {t('send_email')}
          </Button>
          {message && <Typography color="textSecondary">{message}</Typography>}
        </form>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Link component={RouterLink} to="/settings/login" variant="body2">
            {t('go_to_login_page')}
            </Link>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default ForgotPassword;
