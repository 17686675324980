import React, { FC, useState, useRef } from "react";
import { Box, Button, CircularProgress, Stack, Typography, TextField } from "@mui/material";
import axios from "axios";
import DownloadIcon from "@mui/icons-material/Download";

export const RagMakerPage: FC = () => {
  const [inputUrl, setInputUrl] = useState<string>("");
  const [s3Links, setS3Links] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [requestLog, setRequestLog] = useState<string>("");
  const [responseLog, setResponseLog] = useState<string>("");
  const logRef = useRef<HTMLDivElement | null>(null);

  // Function to call the scraping API with the input URL
  const researchCompetitorSite = async () => {
    if (inputUrl.trim() === "") return;

    setIsLoading(true);
    setS3Links([]);
    setRequestLog(`Requesting URL: ${inputUrl}`);
    setResponseLog("");

    try {
      const response = await axios.get(
        `https://wb1wp5h089.execute-api.ap-northeast-1.amazonaws.com/dev/crawlee?urls=${encodeURIComponent(inputUrl)}`
      );
      const s3Urls = response.data;
      setS3Links(s3Urls); // Set the received S3 links
      setResponseLog(`Response received: ${JSON.stringify(s3Urls)}`);
    } catch (error) {
      console.error("Error fetching data from competitor site:", error);
    } finally {
      setIsLoading(false);
      if (logRef.current) {
        logRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  // Function to download file directly
  const downloadFile = async (url: string, filename: string) => {
    try {
      const response = await axios.get(url, { responseType: "blob" });
      const urlBlob = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = urlBlob;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(urlBlob);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <Box>
      {/* サービスの簡潔な説明 */}
      <Box sx={{ mb: 3, p: 2, backgroundColor: "#e3f2fd", borderRadius: 2 }}>
        <Typography variant="body1">
          RAGメーカーでは、URLからRAG（Retrieval-Augmented Generation）データを作成し、テキスト（JSON形式）でダウンロードすることができます。*
        </Typography>
      </Box>

      {/* URL入力フォーム */}
      <Stack direction="row" spacing={2} alignItems="center">
        <TextField
          label="URL"
          variant="outlined"
          fullWidth
          value={inputUrl}
          onChange={(e) => setInputUrl(e.target.value)}
        />
        <Button variant="contained" color="primary" onClick={researchCompetitorSite} disabled={isLoading}>
          Fetch S3 Links
        </Button>
      </Stack>

      {/* ローディング中の表示 */}
      {isLoading && (
        <Box textAlign="center" my={4}>
          <CircularProgress />
          <Typography variant="body1">Fetching data...</Typography>
        </Box>
      )}

      {/* 取得したS3リンクの表示 */}
      {s3Links.length > 0 && (
        <Box my={4}>
          <Typography variant="h6">Retrieved S3 Links:</Typography>
          <Stack spacing={2}>
            {s3Links.map((link, index) => (
              <Button
                key={index}
                onClick={() => downloadFile(link, `file${index + 1}.json`)}
                variant="outlined"
                startIcon={<DownloadIcon />}
              >
                Download {index + 1}
              </Button>
            ))}
          </Stack>
        </Box>
      )}

      {/* リクエストとレスポンスログ */}
      <Box my={4}>
        <Typography variant="body2" color="textSecondary">
          Request Log: {requestLog}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Response Log: {responseLog}
        </Typography>
      </Box>

      {/* 詳細な説明文 */}
      <Box sx={{ mt: 4 }}>
        <Typography variant="body1" color="textSecondary">
          * RAG（Retrieval-Augmented Generation）とは、AIが外部の情報を参照しながら生成を行う技術です。RAGを利用することで、生成AIが単体で生成する場合に比べ、より正確で最新の情報を基にした回答を得ることが可能です。RAGメーカーで作成したRAGデータは、他のAIツールでも使用することができ、AIの回答精度を大幅に向上させることができます。
        </Typography>
      </Box>

      <div ref={logRef}></div>
    </Box>
  );
};
