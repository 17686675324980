import { loadStripe } from "@stripe/stripe-js";
import { FC, useEffect, useState } from "react";
import { CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Typography } from "@mui/material";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { useAppContext } from "../context/AppContext";
import { getFunctions, httpsCallable, HttpsCallableResult } from "firebase/functions";
import { db } from "../config/firebase";
import { Plan } from '../types';  // Plan型をインポート
import { useLocation } from "react-router-dom";

const stripePublicKey = process.env.REACT_APP_ENV === 'production'
  ? process.env.REACT_APP_STRIPE_PUBLIC_KEY_LIVE
  : process.env.REACT_APP_STRIPE_PUBLIC_KEY_TEST;

if (!stripePublicKey) {
  throw new Error('Stripe 公開キーが設定されていません。環境変数を確認してください。');
}

export const SettingPlanPage: FC = () => {
  const [currentPlanId, setCurrentPlanId] = useState<string>('');  // 現在加入中のプランID
  const [plans, setPlans] = useState<Plan[]>([]);  // 全プラン情報
  const [loading, setLoading] = useState(true);  // ローディング状態管理
  const { userId } = useAppContext();  // 現在のユーザー情報
  const functions = getFunctions();  // Firebase Functionsのインスタンスを取得
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const status = queryParams.get('status');

  useEffect(() => {
    // 支払い結果に基づいてユーザーに通知
    if (status === 'success') {
      alert('支払いが成功しました！');
    } else if (status === 'cancel') {
      alert('支払いがキャンセルされました。');
    }
  }, [status]);

  // Firestoreからサブスクリプション情報とプラン情報を取得
  useEffect(() => {
    if (!userId) return;

    const fetchUserSubscription = async () => {
      try {
          // Firestoreからユーザーのサブスクリプション情報を取得
          const userRef = doc(db, 'users', userId);
          const userDoc = await getDoc(userRef);
          if (userDoc.exists()) {
              const userData = userDoc.data();
              setCurrentPlanId(userData.stripeSubscriptionId || '');  // サブスクリプションIDを設定
          }
            const productsList: Plan[] = [];

          try {
            const getProductsAndPrices = httpsCallable(functions, 'getProductsAndPrices');
            const result = await getProductsAndPrices();
            // const productsList: Plan[] = result.data.products;
            const data = result.data as { products: Plan[] };
            setPlans(data.products);
            // setPlans(productsList);
            console.error(data.products);
            setLoading(false);
          } catch (error) {
            console.error('Failed to fetch products and prices:', error);
            setLoading(false);
          }


          // setPlans(productsList);
          setLoading(false);
      } catch (error) {
          console.error('Failed to fetch plans and subscription:', error);
          setLoading(false);
      }
    };

    fetchUserSubscription();
  }, [userId]);

  // サブスクリプション登録処理
  const handleCheckout = async (planId: string) => {
    try {
      const createCheckoutSession = httpsCallable(functions, 'createCheckoutSession');
      const baseUrl = process.env.REACT_APP_ENV === 'production'
        ? 'https://writer.zero-one.llc/settings/plan'
        : 'http://localhost:3000/settings/plan';
      const successUrl = `${baseUrl}?status=success`;
      const cancelUrl = `${baseUrl}?status=cancel`;

      const response: HttpsCallableResult<any> = await createCheckoutSession({
        priceId: planId,
        successUrl,
        cancelUrl
      });

      const sessionId: string = response.data?.sessionId as string;
      const stripe = await loadStripe(stripePublicKey);
      if (stripe) await stripe.redirectToCheckout({ sessionId });
    } catch (error) {
      console.error('Failed to create checkout session:', error);
      alert('支払いのセッションの作成に失敗しました。');
    }
  };

  // サブスクリプションキャンセル処理
  const handleCancelSubscription = async () => {
    if (!userId) {
      alert("ユーザーIDが見つかりません。");
      return;
    }

    const deleteCustomer = httpsCallable(functions, 'deleteStripeCustomer');

    setLoading(true);
    try {
      await deleteCustomer({ userId });
      alert("解約処理が完了しました。");
    } catch (error) {
      console.error("解約処理に失敗しました。", error);
      alert("解約処理に失敗しました。");
    }
    setLoading(false);
  };

  if (loading) return <CircularProgress />;

  return (
    <>
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>プラン名</TableCell>
            <TableCell align="right">価格</TableCell>
            <TableCell>説明</TableCell>
            <TableCell>手続き</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {plans.map((plan) => (
            <TableRow key={plan.id} selected={plan.id === currentPlanId}>
              <TableCell component="th" scope="row">{plan.name}</TableCell>
              <TableCell align="right">{plan.prices[0].amount }円</TableCell>
              <TableCell>
                <Typography variant="body2">{plan.description}</Typography>
              </TableCell>
              <TableCell>
                {currentPlanId === "" ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleCheckout(plan.prices[0].priceId)}
                  >
                    プラン登録
                  </Button>
                ) : plan.id === currentPlanId ? (
                  <Typography variant="body2" color="textSecondary">
                    現在のプラン
                  </Typography>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleCheckout(plan.id)}
                  >
                    プラン変更
                  </Button>
                )}
              </TableCell>
            </TableRow>
          ))}
          {currentPlanId && (
            <TableRow>
              <TableCell colSpan={4} align="right">
                <Typography
                  variant="body2"
                  color="primary"
                  onClick={handleCancelSubscription}
                  style={{ cursor: 'pointer', textDecoration: 'underline' }}
                >
                  解約する
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
    </>
  );
};
