import { FC, useState } from "react";
import { Box, Button, Typography, Stack, TextField, CircularProgress, Alert } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../config/firebase";
import { useAppContext } from "../context/AppContext";
import { useTranslation } from "react-i18next";

type Form = {
  name: string;
  promptText: string;
};

export const PromptNewPage: FC = () => {
  const { control, handleSubmit, formState: { errors } } = useForm<Form>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { userId } = useAppContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const submit = async (data: Form) => {
    if (isSubmitting) return; // 二重送信防止

    setIsSubmitting(true);
    setErrorMessage(null);

    try {
      // Firebaseに新しいプロンプトを登録
      const promptCollectionRef = collection(db, "prompts");
      await addDoc(promptCollectionRef, {
        name: data.name,
        promptText: data.promptText,
        userId: userId,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        status: 1, // 1 = open
      });
      navigate("/prompts"); // 登録後に一覧ページへ移動
    } catch (error) {
      console.error("プロンプトの登録に失敗しました", error);
      setErrorMessage("プロンプトの登録中にエラーが発生しました。再試行してください。");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      component="form"
      noValidate
      onSubmit={handleSubmit(submit)}
      sx={{ p: 5, mt: 4, mx: "auto", maxWidth: "80%", border: '1px dashed grey', borderRadius: 2, backgroundColor: '#f7f7f7' }}
    >
      <Stack spacing={3}>
        <Typography variant="h5" textAlign="center">
          {t('新しいプロンプトを登録')}
        </Typography>

        <Controller
          name="name"
          control={control}
          defaultValue=""
          rules={{ required: 'プロンプト名は必須です' }}
          render={({ field }) => (
            <TextField
              {...field}
              label="プロンプト名"
              fullWidth
              
              error={!!errors.name}
              helperText={errors.name?.message}
            />
          )}
        />

        <Controller
          name="promptText"
          control={control}
          defaultValue=""
          rules={{
            required: 'プロンプトテキストは必須です',
            maxLength: { value: 1000, message: 'プロンプトテキストは1000文字以内です' }
          }}
          render={({ field }) => (
            <TextField
              {...field}
              label="プロンプトテキスト"
              fullWidth
              multiline
              rows={20}
              error={!!errors.promptText}
              helperText={errors.promptText?.message}
            />
          )}
        />

        {errorMessage && (
          <Alert severity="error">{errorMessage}</Alert>
        )}

        <Button type="submit" variant="contained" fullWidth disabled={isSubmitting}>
          {isSubmitting ? <CircularProgress size={24} /> : t('登録する')}
        </Button>
      </Stack>
    </Box>
  );
};
