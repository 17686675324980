// Step1Form.jsx
import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Avatar, Box, Button, CircularProgress, Divider, FormControl, FormLabel, IconButton, List, ListItem, ListItemAvatar, ListItemText, MenuItem, Modal, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { Backdrop, ButtonGroup, ImageList, ImageListItem, InputLabel, Link,  SelectChangeEvent, withStyles } from '@mui/material';
import axios from "axios";
import FormGroup from '@mui/material/FormGroup';
import { Editor } from 'react-draft-wysiwyg';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import GirlIcon from '@mui/icons-material/Girl';
import ContentPasteSearchTwoToneIcon from '@mui/icons-material/ContentPasteSearchTwoTone';
import LocationSearchingTwoToneIcon from '@mui/icons-material/LocationSearchingTwoTone';
import PersonSearchTwoToneIcon from '@mui/icons-material/PersonSearchTwoTone';
import QueryStatsTwoToneIcon from '@mui/icons-material/QueryStatsTwoTone';
import ScreenSearchDesktopTwoToneIcon from '@mui/icons-material/ScreenSearchDesktopTwoTone';
import { red } from '@mui/material/colors';
import { Site, Outline,Article,Blog,ImageData,ArticleCreationData,News } from '../../types';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useTranslation } from 'react-i18next';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import moment from 'moment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

// Suggestion 型を定義
type Suggestion = {
  keyword: string;       // 複合キーワード
  competition: string;   // 競合度
  searchVolume: string;  // 検索ボリューム
  keywordType: string;
};
// NewsItem 型を定義
type NewsItem = {
  id: string;
  title: string;
  description: string;
  link: string;
  pubDate: Date;
};
// Props の型定義を追加
type Step1FormProps = {
  inputKeyword: string;
  setInputKeyword: (keyword: string) => void;
  onSubmitKeyword: () => void;
  gotoStep2: () => void;
  gotoStep3: () => void;
  // suggestions:  string[]; // suggestions 配列の型を Suggestion 型の配列として追加
  suggestions: Suggestion[];  // string[] から Suggestion[] に変更
  setSelectedKeyword: (keyword: string) => void;
  selectedKeyword: string; // この行を追加
  isLoadingKeywords: boolean;
  isLoadingNews: boolean;
  setIsLoadingNews: (isLoading:boolean) => void;
  isLoadingSites: boolean;
  sites:  Site[];

  setSites: React.Dispatch<React.SetStateAction<Site[]>>;   
  setTarget: (target: string) => void;
  target:  string;
  role: string;
  setRole: (role: string) => void;
  selectedSites: Site[];
  setSelectedSites: (sites:Site[]) => void;
  setSelectedSitesStr: (str:string) => void;
  news:  News[];
  setNews: React.Dispatch<React.SetStateAction<News[]>>; 
  selectedNews:  News[];
  setSelectedNewsStr: (str:string) => void;
  setSelectedNews: React.Dispatch<React.SetStateAction<News[]>>;  
  currentLanguage: string; 
  allNews: NewsItem[]; // The list of news articles
  selectedAllNews: NewsItem[]; // Renamed from selectedNews
  setSelectedAllNews: (news: NewsItem[]) => void; // Setter for selectedAllNews
};

const Step1Form: React.FC<Step1FormProps> 
 = ({ allNews,selectedAllNews,setSelectedAllNews,inputKeyword, setInputKeyword, onSubmitKeyword ,
  suggestions,setSelectedKeyword = '',selectedKeyword,
  gotoStep2,gotoStep3,sites,setSites,setIsLoadingNews,
  setTarget,  isLoadingKeywords ,target,role,setRole,selectedSites, setSelectedSites, setSelectedSitesStr,news,setNews,selectedNews,setSelectedNews,setSelectedNewsStr,currentLanguage}) => {
  const [selectedBlogId, setSelectedBlogId] = React.useState<string>('');
  const [isLoadingSites, setIsLoadingSites] = React.useState(false);
  const [inputError, setInputError] = React.useState<string>('');
  const [blogs, setBlogs] = useState<Blog[]>([]);
  const [editorState, setEditorState] = React.useState(() => EditorState.createEmpty());
  const [expanded, setExpanded] = useState(false);
  // 選択されたサイトを管理するための新しい状態
  // const [selectedSites, setSelectedSites] = React.useState<Site[]>([]);
  const { t } = useTranslation();
  const [isDescriptionVisible, setDescriptionVisible] = useState(false);


  // 競合サイトのリストが更新されたときに、最初の3項目を選択する
  useEffect(() => {
    if (sites.length > 0) {
        setSelectedSites(sites.slice(0, 3)); // 最初の3項目を選択
        setSelectedSitesStr(
        selectedSites.map(site => site.title+site.snippet).join(', ')
      );
    }
  }, [sites, setSelectedSites]);


  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const toggleDescription = () => {
    setDescriptionVisible(!isDescriptionVisible);
  };
  // チェックボックスの変更をハンドルする関数
  const handleSiteChange = (site: Site, isChecked: boolean) => {
    console.log(selectedSites);
    if (isChecked) {
      // 最大選択数を3に設定し、それ以下の場合のみ追加
      if (selectedSites.length < 3) {
        setSelectedSites([...selectedSites, site]);
      }
    } else {
      // 選択が解除された場合、リストからそのサイトを削除
      setSelectedSites(selectedSites.filter((selectedSite) => selectedSite.title !== site.title));
    }
  };
  const handleNewsChange = (news: News, isChecked: boolean) => {
    console.log(selectedNews);
    if (isChecked) {
      // 最大選択数を3に設定し、それ以下の場合のみ追加
      if (selectedNews.length < 3) {
        setSelectedNews([...selectedNews, news]);
      }
    } else {
      // 選択が解除された場合、リストからそのサイトを削除
      setSelectedNews(selectedNews.filter((selectedNews) => selectedNews.title !== news.title));
    }
  };
  
  // Step1Form 内部でのローカル状態の追加
  // const [selectedKeyword, setSelectedKeyword] = React.useState<string>();
  // 入力フィールドの変更イベントハンドラ
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    // if (e.target.value.trim() === '') {
    //   setInputError(t('enter_keywords'));
    // } else {
      setInputKeyword(e.target.value);
    // }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (typeof setSelectedKeyword === 'function') {
      setSelectedKeyword(event.target.value);  // 選択されたキーワードをセット
    }
  };
  

  // キーワード提案関数
  const handleKeywordSubmit = () => {
    if (inputKeyword.trim() === '') {
      setInputError(t('enter_keywords'));
    } else {
      onSubmitKeyword();
    }
  };
  const handlepostArticle = (article: Article | null) => {
    if (!article) return; // null チェック
  
    console.log("Posting article: ", article);
    // 記事を投稿するロジックをここに追加
  };


  const researchCompetitorSite = async () => {
    if (selectedKeyword.trim()) {
      setIsLoadingSites(true); // ローディング開始
      try {
        console.log(selectedKeyword);
  
        // キーワードに基づいた検索APIにリクエスト
        const response = await axios.get(`https://wb1wp5h089.execute-api.ap-northeast-1.amazonaws.com/dev/search-api?q=${encodeURIComponent(selectedKeyword)}&lr=${currentLanguage}`);
  
        const sites = response.data.items;
  
        // 競合度や検索ボリュームの推定値を取得するための別APIコール
        // const keywordDataResponse = await axios.get(`https://api-keyword-data.example.com?keyword=${encodeURIComponent(selectedKeyword)}&language=${currentLanguage}`);
  
        // const keywordData = keywordDataResponse.data;
  
        const extractedData = sites.map((site: Site) => ({
          title: site.title,
          link: site.link,
          snippet: site.snippet,
          image: site.pagemap?.cse_image?.[0]?.src, // サムネイル画像のURL
          // competition: keywordData.competition, // 競合度の追加
          // searchVolume: keywordData.searchVolume // 検索ボリュームの追加
        }));
  
        setSites(extractedData); // 競合サイト情報を更新
      } catch (error) {
        // エラー内容を出力
        // console.error("Error fetching competitor site data:", error.message || error);
      } finally {
        setIsLoadingSites(false); // ローディング終了
      }
    }
  };
  
  

  // EditorState が変更されたときの処理
  const onEditorStateChange = (newState:EditorState) => {
    setEditorState(newState);
  };
  const research = async () =>{
    setSites([]); // 関連サイトリストをクリア
    setNews([]); // 関連サイトリストをクリア
    // researchNews();
    console.log(news);
    researchCompetitorSite();

  } 
   function setShowErrorModal(arg0: boolean): void {
     throw new Error('Function not implemented.');
   }

  return (
    <>
          {allNews.map((newsItem) => (
          <Box key={newsItem.id}>
            <ListItem sx={{ 
              border: '1px solid #000',  // 外枠を太めに設定
              borderRadius: '0px',       // 角丸をなしに
              padding: '12px',
              boxShadow: '0 2px 5px rgba(0,0,0,0.1)'
            }}>
            <ListItemText
              primary={
                <Typography 
                  variant="body1"
                  sx={{ 
                    whiteSpace: 'nowrap', 
                    overflow: 'hidden', 
                    textOverflow: 'ellipsis',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%'
                  }}
                >
                  <strong>{newsItem.title}</strong>
                  <span style={{ marginLeft: 'auto', whiteSpace: 'nowrap' }}>
                  {moment(newsItem.pubDate).format("YYYY-MM-DD")}
                  </span>
                  <IconButton onClick={toggleDescription}>
                    {isDescriptionVisible ? <RemoveIcon /> : <AddIcon />}
                  </IconButton>
                </Typography>
              }
              secondary={
                isDescriptionVisible && (
                  <Typography variant="body2" color="textSecondary">
                    {newsItem.description}
                  </Typography>
                )
              }
            />
              {/* <Button component={Link} to={newsItem.link} sx={{ ml: 2 }}>
                {t("記事を読む")}
              </Button> */}
            </ListItem>
            <Divider />
          </Box>
        ))}
  <Grid container spacing={1}
      direction="row"
      justifyContent="center"
      // alignItems="center"
      >
    <Grid item xs={12} lg={12}>
    {/* <Container> */}
    <FormGroup>
    {/* <Box component="section" sx={{ p: 1,marginBottom:5,border: '1px dashed grey' }}> */}
    <Stack  my={2} direction="row" justifyContent="start" spacing={1}>
        <ContentPasteSearchTwoToneIcon />
        <Typography variant="h5">{t('select_keywords')}</Typography>
        </Stack>
        <TextField 
        variant="outlined" 
        margin="normal"
        fullWidth 
        label={t('keyword')}
        value={inputKeyword}
        // onChange={(e) => setInputKeyword(e.target.value)} // この行を追加
        onChange={handleInputChange}
        error={inputError !== ''}
        helperText={inputError}
        />




        </FormGroup>
        <Button variant="contained" color="primary" fullWidth onClick={handleKeywordSubmit}>
          <ArrowDownwardIcon/>
          {t('compound_keywords_research')}
          <ArrowDownwardIcon/>
        </Button>
       

          { isLoadingKeywords && (
                <Box component="section" sx={{ my:20,alignItems: 'center',textAlign:'center'}} ><CircularProgress /></Box>
          )}
      { suggestions.length > 0 && (
        <>



<Box component="section" sx={{ p: 1, marginBottom: 5, marginTop: 5, border: '1px dashed grey', width: 1 }}>
  <Stack my={2} direction="row" justifyContent="start" spacing={1}>
    <QueryStatsTwoToneIcon />
    <Typography variant="h5" my={2}>{t('compound_keywords_list')}</Typography>
  </Stack>
  <Box sx={{ mb: 2 }}>
    <Typography variant="body2" color="textSecondary">
      <strong>検索ボリューム:</strong> キーワードがどの程度検索されているか（High, Medium, Low）<br />
      <strong>競合度:</strong> キーワードの競合の高さ（High, Medium, Low）<br />
      <strong>キーワードタイプ:</strong> Large - 高度に検索される重要なキーワード, Medium - 中程度の検索, Small - ニッチキーワード
    </Typography>
  </Box>
  <RadioGroup aria-label="selectedKeyword" name="selectedKeyword" row value={selectedKeyword} onChange={handleChange} sx={{ p: 0.5 }}>
    {suggestions.map((suggestion, index) => {
      // キーワードタイプに応じて背景色を設定
      let backgroundColor;
      if (suggestion.keywordType === 'large') {
        backgroundColor = '#e57373'; // 赤系
      } else if (suggestion.keywordType === 'medium') {
        backgroundColor = '#ffb74d'; // オレンジ系
      } else {
        backgroundColor = '#fff176'; // 黄色系
      }

      return (
        <Box
          key={index}
          sx={{
            m: 0.1,
            border: '1px dashed grey',
            background: backgroundColor,
            borderRadius: '5px',
          }}
        >
          <FormControlLabel
            control={<Radio />}
            label={
              <Stack spacing={0.5}>
                <Typography variant="button">{suggestion.keyword}</Typography>
                <Typography variant="caption" color="textSecondary">
                  競合度: {suggestion.competition} | 検索ボリューム: {suggestion.searchVolume}
                </Typography>
              </Stack>
            }
            value={suggestion.keyword}
            sx={{ fontSizeAdjust: "inherit" }}
          />
        </Box>
      );
    })}
  </RadioGroup>
</Box>

        
        { selectedKeyword.length > 0 && (

        <Button variant="contained" color="primary" fullWidth  onClick={research}>
          <ArrowDownwardIcon/>
          {t('competitor_site_research')}
        <ArrowDownwardIcon/>
      </Button>
      )}
        </>
      )}
        
    {/* </Container> */}
    </Grid>
    <Grid item  xs={12}  lg={12}>
      { isLoadingSites && (
         <Box component="section"  sx={{ my:20,alignItems: 'center',textAlign:'center'}}>
            <CircularProgress />
          </Box>
      )}
      { (news.length > 0 || sites.length > 0)&& (
      <Box component="section" sx={{  p: 1,marginBottom:1,marginTop:1,border: '1px dashed grey',width: 1 }}>
      <FormControl component="fieldset">


      <Stack  my={2} direction="row" justifyContent="start" spacing={1}>
        <ScreenSearchDesktopTwoToneIcon />
        <Typography variant="h5" gutterBottom>{t('top_search_competitor_sites')}</Typography>
      </Stack>
      <List sx={{ 
    width: '100%', 
    bgcolor: 'background.paper', 
    overflowX: 'auto', // 水平スクロールを許可
    borderRadius: '10px', 
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)' 
  }}>
    {sites.map((site, index) => (
      <ListItem 
        key={index}
        sx={{ 
          display: "flex", 
          alignItems: "flex-start", 
          paddingY: 2, 
          bgcolor: index % 2 === 0 ? 'rgba(0, 0, 0, 0.06)' : 'rgba(0, 0, 0, 0.12)', 
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)', 
          '&:last-child': {
            border: 0,
          },
          flexDirection: { xs: 'column', sm: 'row' } // スマートフォンでは縦に並べる
        }}
      >
        <ListItemAvatar>
          <Avatar alt={site.title} src={site.image} sx={{ width: 40, height: 40, marginRight: 2 }} />
        </ListItemAvatar>
        
        <ListItemText
          primary={<Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>{site.title}</Typography>}
          secondary={
            <>
              <Typography
                variant="body2"
                color="text.secondary"
                component="span"
                style={{ display: 'block', marginBottom: '1px' }}
              >
                {site.snippet}
              </Typography>

            </>
          }
        />
        <Checkbox
          onChange={(e) => handleSiteChange(site, e.target.checked)}
          checked={selectedSites.some((selectedSite) => selectedSite.title === site.title)}
          sx={{ marginLeft: 'auto' }}
        />
      </ListItem>
    ))}
  </List>

      </FormControl>

      <Button variant="contained" color="secondary" fullWidth  onClick={gotoStep3} sx={{p:1,margin:1}}>
      {/* <ArrowDownwardIcon/>{t('suggest_article_step3')}<ArrowDownwardIcon/> */}
      <ArrowDownwardIcon/>{t('suggest_article_step3')}<ArrowDownwardIcon/>
      </Button>
      </Box>
      
       )} 
    </Grid>
  </Grid>

    </>
  );
}

export default Step1Form;
