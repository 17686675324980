import {
  limit,
  collection,
  onSnapshot,
  orderBy,
  query,
  doc,
  updateDoc,
} from "firebase/firestore";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Button,
  IconButton,
  CircularProgress,
  Snackbar,
  Pagination,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { db } from "../config/firebase";
import { useTranslation } from "react-i18next";
import { FC, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import RefreshIcon from "@mui/icons-material/Refresh";

type Rag = {
  id: string;
  name: string;
  content: string;
  status: number;
  updatedAt: any;
};

export const RagListPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [rags, setRags] = useState<Rag[]>([]);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [page, setPage] = useState(1);
  const itemsPerPage = 20;

  useEffect(() => {
    const fetchRags = () => {
      setLoading(true);
      const ragCollectionRef = collection(db, "rags");
      const q = query(ragCollectionRef, orderBy("updatedAt", "desc"), limit(itemsPerPage));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        if (snapshot.empty) {
          console.log("RAGが見つかりません");
        } else {
          const newRags: Rag[] = snapshot.docs.map((doc) => ({
            id: doc.id,
            name: doc.data().name,
            content: doc.data().content,
            status: doc.data().status,
            updatedAt: doc.data().updatedAt,
          }));
          setRags(newRags);
        }
        setLoading(false);
      });
      return () => unsubscribe();
    };

    fetchRags();
  }, [page]);

  const handleDeleteRag = async (ragId: string) => {
    try {
      const ragRef = doc(db, "rags", ragId);
      await updateDoc(ragRef, { status: 3 }); // 3 = 削除済み
      setMessage("RAGを削除としてマークしました");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("RAGの削除に失敗しました", error);
      setMessage("RAGの削除に失敗しました");
      setSnackbarOpen(true);
    }
  };

  const handleUpdateRag = (ragId: string) => {
    navigate(`/rag/edit/${ragId}`);
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <Box sx={{ width: "80%", m: "auto", p: 3 }}>
      <Typography variant="h5" sx={{ mb: 3 }}>
        {t("登録済みのRAG")}
      </Typography>

      {loading && <CircularProgress />} {/* スピナーの表示 */}

      <List>
        {rags.map((rag) => (
          <Box key={rag.id}>
            <ListItem alignItems="flex-start">
              <ListItemText
                primary={rag.name}
                secondary={
                  <>
                    <Typography variant="body2" color="text.secondary" noWrap>
                      {rag.content.substring(0, 50)}...
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {t("ステータス")}:
                      {(() => {
                        switch (rag.status) {
                          case 1:
                            return 'オープン';
                          case 2:
                            return 'クローズ';
                          case 3:
                            return '削除済み';
                          case 4:
                            return '審査中';
                          default:
                            return '不明';
                        }
                      })()}
                    </Typography>
                  </>
                }
              />
              <IconButton edge="end" aria-label="edit" onClick={() => handleUpdateRag(rag.id)} disabled={loading}>
                <EditIcon />
              </IconButton>
              <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteRag(rag.id)} disabled={loading}>
                <DeleteIcon />
              </IconButton>
            </ListItem>
            <Divider variant="inset" component="li" />
          </Box>
        ))}
      </List>

      <Pagination
        count={Math.ceil(rags.length / itemsPerPage)}
        page={page}
        onChange={handlePageChange}
        sx={{ mt: 2 }}
      />

      {/* 成功・失敗の通知 */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={message}
      />
    </Box>
  );
};
